<template>
  <div>
    <a-row :gutter="24" type="flex">
      <a-col :span="24" class="mb-24">
        <a-card
          :bordered="false"
          class="header-solid h-full"
          :bodyStyle="{ padding: 4 }"
        >
          <template #title>
            <a-row type="flex" align="middle">
              <a-col :span="24" :md="12" class="top-left-header">
                <h5 class="font-semibold m-0">
                  Offer {{ isEditMode ? "Edit" : "Create" }}
                  <a-spin v-if="confirmLoading" />
                </h5>
              </a-col>
            </a-row>
          </template>
          <div class="new-upload-data">
            <div style="display: flex; justify-content: space-between">
              <div style="flex: 1; margin-right: 5px">
                <label style="font-weight: bold; font-size: 16px"
                  >Category <span style="color: red">*</span></label
                >
                <a-select
                  v-model:value="offer.category_id"
                  placeholder="Select a category"
                  style="width: 100%"
                  :options="categoryOptions"
                  show-search
                  :filter-option="filterOption"
                  @change="changeCategory"
                >
                </a-select>
              </div>
              <div style="flex: 1; margin-right: 5px">
                <label style="font-weight: bold; font-size: 16px"
                  >Partner <span style="color: red">*</span></label
                >
                <a-select
                  v-model:value="offer.partner_id"
                  placeholder="Select a partner"
                  style="width: 100%"
                  :options="partnerfilterList"
                  show-search
                  :filter-option="filterOption"
                >
                </a-select>
              </div>
              <div style="flex: 1">
                <label style="font-weight: bold; font-size: 16px"
                  >Package <span style="color: red">*</span></label
                >
                <a-select
                  v-model:value="offer.package_id"
                  placeholder="Select a package"
                  style="width: 100%"
                  :options="packageOptions"
                  show-search
                  :filter-option="filterOption"
                >
                </a-select>
              </div>
            </div>
            <div v-if="isEditMode">
              <label style="margin-right: 10px">Offer Status</label>
              <a-switch v-model:checked="offer.status" />
            </div>

            <div>
              <h4 style="font-weight: bold">
                Title <span style="color: red">*</span>
              </h4>
              <a-row>
                <a-input
                  v-model="offer.titleEn"
                  placeholder="Title (English)"
                />
                <a-input
                  v-model="offer.titleBn"
                  placeholder="Title (Bangla)"
                  style="margin-top: 4px"
                />
              </a-row>
            </div>

            <div>
              <h4 style="font-weight: bold">
                Description <span style="color: red">*</span>
              </h4>
              <a-row>
                <a-input
                  v-model="offer.descriptionEn"
                  placeholder="Description (English)"
                />
                <a-input
                  v-model="offer.descriptionBn"
                  placeholder="Description (Bangla)"
                  style="margin-top: 4px"
                />
              </a-row>
            </div>

            <div>
              <h4 style="font-weight: bold">
                Information Title <span style="color: red">*</span>
              </h4>
              <a-row>
                <a-input
                  v-model="offer.informationTitleEn"
                  placeholder="Information Title (English)"
                />
                <a-input
                  v-model="offer.informationTitleBn"
                  placeholder="Information Title (Bangla)"
                  style="margin-top: 4px"
                />
              </a-row>
            </div>
            <div>
              <h4>Information Details <span style="color: red">*</span></h4>
              <a-row>
                <vue-editor
                  v-model="offer.informationDetailsEn"
                  placeholder="Information Details (English)"
                />
                <vue-editor
                  v-model="offer.informationDetailsBn"
                  placeholder="Information Details (Bangla)"
                  style="margin-top: 4px"
                />
              </a-row>
            </div>

            <div>
              <h4>Facility:</h4>
              <a-select
                v-model:value="offer.facilities"
                mode="multiple"
                placeholder="Select a icon"
                style="width: 100%"
                :options="iconOptions"
                @change="changeIcons"
              >
              </a-select>
            </div>
            <div>
              <h4>Avail Offer Title <span style="color: red">*</span></h4>
              <a-input
                v-model="offer.availOfferTitleEn"
                placeholder="Avail Offer (English)"
              />
              <a-input
                v-model="offer.availOfferTitleBn"
                placeholder="Avail Offer (Bangla)"
                style="margin-top: 4px"
              />
            </div>
            <div>
              <h4>Avail Offer Details <span style="color: red">*</span></h4>
              <vue-editor
                v-model="offer.availOfferDetailsEn"
                placeholder="Avail Offer (English)"
              />
              <vue-editor
                v-model="offer.availOfferDetailsBn"
                placeholder="Avail Offer (Bangla)"
                style="margin-top: 4px"
              />
            </div>

            <div>
              <h4>Terms and Condition <span style="color: red">*</span></h4>
              <a-input
                v-model="offer.termsAndConditionsEn"
                placeholder="Terms and Condition (English)"
              />
              <a-input
                v-model="offer.termsAndConditionsBn"
                placeholder="Terms and Condition (Bangla)"
                style="margin-top: 4px"
              />
            </div>

            <div>
              <h4>Offer Validity <span style="color: red">*</span></h4>
              <div style="display: flex">
                <div style="margin-right: 20px">
                  <div>Start Date</div>
                  <a-date-picker
                    style="width: 50%"
                    v-model="offer.startDate"
                    placeholder="Start Date"
                    show-time
                    format="YYYY-MM-DD HH:mm:ss"
                    @change="onStartDateChange"
                  />
                </div>
                <div>
                  <div>End Date</div>
                  <a-date-picker
                    style="width: 50%"
                    v-model="offer.endDate"
                    placeholder="End Date"
                    show-time
                    format="YYYY-MM-DD HH:mm"
                    @change="onEndDateChange"
                  />
                </div>
              </div>
            </div>
            <div>
              <h4>
                Contact Information Title <span style="color: red">*</span>
              </h4>
              <a-input
                v-model="offer.contactInfoTitleEn"
                placeholder="Contact Information Title (English)"
              />
              <a-input
                v-model="offer.contactInfoTitleBn"
                placeholder="Contact Information Title (Bangla)"
                style="margin-top: 4px"
              />
            </div>
            <div>
              <h4>Contact Information:</h4>
              <div class="new-upload-data" style="margin: 0 16px">
                <div>
                  <h6>Address Title <span style="color: red">*</span></h6>
                  <a-input
                    v-model="offer.address1TitleEn"
                    placeholder="Address Title (English)"
                  />
                  <a-input
                    v-model="offer.address1TitleBn"
                    placeholder="Address Title (Bangla)"
                    style="margin-top: 4px"
                  />
                </div>
                <br />
                <div>
                  <h6>Phone Number <span style="color: red">*</span></h6>
                  <a-input
                    v-model="offer.address2TitleEn"
                    placeholder="Phone Number (English)"
                  />
                  <a-input
                    v-model="offer.address2TitleBn"
                    placeholder="Phone Number (Bangla)"
                    style="margin-top: 4px"
                  />
                </div>

                <br />

                <div>
                  <h6>Email <span style="color: red">*</span></h6>
                  <a-input
                    v-model="offer.address3TitleEn"
                    placeholder="Email"
                  />
                </div>
              </div>
            </div>
            <div style="display: flex">
              <div style="flex: 1">
                <h6 style="margin-right: 16px">Weight</h6>
                <a-input-number
                  style="width: 50%"
                  v-model="offer.weight"
                  placeholder="Weight"
                />
              </div>
              <div style="flex: 1">
                <h6 style="margin-right: 16px">Discount Amount</h6>
                <a-input-number
                  style="width: 50%"
                  v-model="offer.discountAmount"
                  placeholder="Discount Amount"
                />
                <a-select
                  v-model:value="offer.discountType"
                  placeholder="Select a discount Type"
                  :options="[
                    { value: 'percentage', label: 'Percentage' },
                    { value: 'fixed', label: 'Fixed' },
                  ]"
                  @change="changeDiscountType"
                  style="margin-left: 5px"
                >
                </a-select>
              </div>
            </div>

            <div>
              <h4>Cover Images: <span style="color: red">*</span></h4>
              <div class="new-upload-data" style="margin: 0 16px">
                <div v-for="(item, index) in this.offer.coverImages">
                  <div v-if="isEditMode && item.icon.length > 0" class="mt-5">
                    <img
                      :src="item.icon"
                      :alt="`Cover image ${index + 1}`"
                      height="100"
                      width="100"
                    />
                  </div>
                  <label>Cover Image {{ index + 1 }}</label>
                  <a-row type="flex" justify="space-between">
                    <div style="width: 50%">
                      <a-input
                        type="file"
                        @change="onCoverImageFileChange($event, index)"
                        placeholder="Basic usage"
                        accept="image/png, image/jpeg, image/jpg"
                        style="width: 100%"
                      />
                      <small
                        ><i>Minimum image dimension is 1080 x 1080</i></small
                      >
                    </div>
                    <a-row v-if="offer.coverImages.length > 1" type="flex">
                      <a-col :span="2">
                        <a-button type="danger" @click="removeCoverImage(index)"
                          >Remove</a-button
                        >
                      </a-col>
                    </a-row>
                  </a-row>
                </div>
                <a-row type="flex" justify="start">
                  <a-col :span="2">
                    <a-button type="primary" @click="addCoverImage"
                      >Add Cover Image</a-button
                    >
                  </a-col>
                </a-row>
              </div>
            </div>
            <div v-if="errorMsg.length" class="ant-btn-danger mt-5">
              {{ errorMsg }}
            </div>
            <div class="mt-5 left-align-content">
              <a-button
                type="primary"
                @click="onSubmit"
                :loading="confirmLoading"
                >Submit</a-button
              >
            </div>
          </div>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { sendRequest } from "@/http/axios.method";
import { message } from "ant-design-vue";
import { VueEditor } from "vue2-editor";
import _ from "lodash";

export default {
  name: "offer-form",
  components: {
    VueEditor,
  },
  data() {
    return {
      config: {
        Authorization: "Bearer " + this.$store.state.authUser?.token,
      },
      categoryOptions: [],
      selectedCategory: "",
      partnerOptions: [],
      packageOptions: [],
      iconOptions: [],
      confirmLoading: false,
      offer: {
        id: "",
        category_id: "",
        partner_id: "",
        package_id: "",
        titleEn: "",
        titleBn: "",
        descriptionEn: "",
        descriptionBn: "",
        informationTitleEn: "",
        informationTitleBn: "",
        informationDetailsEn: "",
        informationDetailsBn: "",
        facilities: [],
        updatedFacilityIconData: [],
        availOfferTitleEn: "",
        availOfferTitleBn: "",
        availOfferDetailsEn: "",
        availOfferDetailsBn: "",
        termsAndConditionsEn: "",
        termsAndConditionsBn: "",
        startDate: null,
        endDate: null,
        contactInfoTitleEn: "",
        contactInfoTitleBn: "",
        address1TitleEn: "",
        address1TitleBn: "",

        address2TitleEn: "",
        address2TitleBn: "",
        updatedAddress3Icon: "",
        address3TitleEn: "",
        address3TitleBn: "",
        weight: "",
        discountAmount: "",
        discountType: "percentage",
        coverImages: [
          {
            icon: "",
          },
        ],
        updatedCoverImages: [],
        status: true,
      },
      fileContent: "",
      logoContent: "",
      errorMsg: "",
      isEditMode: false,
    };
  },
  async created() {
    this.isEditMode = this.$route.params.id !== undefined;
    await this.getIconList();
    if (this.isEditMode) {
      const offerId = this.$route.params.id;
      await this.fetchOfferById(offerId);
    }
    await this.getCategoryList();
    await this.getPackageList();
  },
  methods: {
    removeCoverImage(index) {
      if (this.offer.coverImages.length > 1) {
        this.offer.coverImages.splice(index, 1);
      }
    },

    addCoverImage() {
      this.offer.coverImages.push({
        icon: "",
      });
    },
    async getIconList() {
      this.confirmLoading = true;
      await sendRequest("get", "admin/active-icons", {}, this.config)
        .then((response) => {
          this.confirmLoading = false;
          if (response.success) {
            let iconList = response.data.icons;
            this.iconOptions = iconList.map(function (icon, label) {
              return {
                value: icon.id,
                label: icon.title,
              };
            });
          } else {
            if (response.status_code === 401) {
              this.$store.dispatch("logout");
              this.$router.push("/login");
            }
          }
        })
        .catch(() => {
          this.confirmLoading = false;
        })
        .finally(() => {
          this.confirmLoading = false;
        });
    },
    async getCategoryList() {
      this.confirmLoading = true;
      await sendRequest("get", "admin/categories/active", {}, this.config)
        .then((response) => {
          this.confirmLoading = false;
          if (response.success) {
            let categoryList = response.data;
            this.selectedCategory = categoryList[0].id;
            if (!this.isEditMode) {
              this.offer.category_id = this.selectedCategory;
            }

            this.getPartnerList();
            this.categoryOptions = categoryList.map(function (category) {
              return {
                value: category.id,
                label: category.name,
              };
            });
          } else {
            if (response.status_code === 401) {
              this.$store.dispatch("logout");
              this.$router.push("/login");
            }
          }
        })
        .catch(() => {
          this.confirmLoading = false;
        })
        .finally(() => {
          this.confirmLoading = false;
        });
    },
    changeCategory(value) {
      this.selectedCategory = value;
      this.offer.partner_id = null;
      this.getPartnerList();
    },
    changeDiscountType(value) {
      this.offer.discountType = value;
    },
    changeIcons(value) {
      this.offer.facilities = value;
    },
    async getPartnerList() {
      this.confirmLoading = true;
      await sendRequest(
        "get",
        `admin/category-wise-partners`,
        { category_id: this.offer.category_id },
        this.config
      )
        .then((response) => {
          this.confirmLoading = false;
          if (response.success) {
            let partners = response.data.partners;
            this.partnerOptions = partners.map(function (partner) {
              return {
                value: partner.id,
                label: _.upperFirst(partner.name.en),
              };
            });
          } else {
            if (response.status_code === 401) {
              this.$store.dispatch("logout");
              this.$router.push("/login");
            }
          }
        })
        .catch(() => {
          this.confirmLoading = false;
        })
        .finally(() => {
          this.confirmLoading = false;
        });
    },
    async getPackageList() {
      this.confirmLoading = true;
      await sendRequest("get", `admin/packages?include=offers`, {}, this.config)
        .then((response) => {
          this.confirmLoading = false;
          if (response.success) {
            let packages = response.data;
            this.packageOptions = packages.map(function (offerPackage, label) {
              return {
                value: offerPackage.id,
                label: _.upperFirst(offerPackage.name.en),
              };
            });
          } else {
            if (response.status_code === 401) {
              this.$store.dispatch("logout");
              this.$router.push("/login");
            }
          }
        })
        .catch(() => {
          this.confirmLoading = false;
        })
        .finally(() => {
          this.confirmLoading = false;
        });
    },
    async fetchOfferById(offerId) {
      this.confirmLoading = true;
      await sendRequest("get", "admin/offers/" + offerId, {}, this.config)
        .then((response) => {
          this.confirmLoading = false;
          if (response.success) {
            let offerData = response.data;
            this.offer.id = offerData.id;
            this.offer.category_id = offerData.category_id;
            this.offer.partner_id = offerData.partner_id;
            this.offer.package_id = offerData.package_id;
            this.offer.titleEn = offerData.offer_title.en;
            this.offer.titleBn = offerData.offer_title.bn;
            this.offer.descriptionEn = offerData.description.en;
            this.offer.descriptionBn = offerData.description.bn;
            this.offer.informationTitleEn = offerData.information.en.title;
            this.offer.informationTitleBn = offerData.information.bn.title;
            this.offer.informationDetailsEn = offerData.information.en.details;
            this.offer.informationDetailsBn = offerData.information.bn.details;
            this.offer.availOfferTitleEn = offerData.avail_offer.en.title;
            this.offer.availOfferTitleBn = offerData.avail_offer.bn.title;
            this.offer.availOfferDetailsEn = offerData.avail_offer.en.details;
            this.offer.availOfferDetailsBn = offerData.avail_offer.bn.details;
            this.offer.termsAndConditionsEn = offerData.term_and_condition.en;
            this.offer.termsAndConditionsBn = offerData.term_and_condition.bn;
            this.offer.startDate = offerData.start_date;
            this.offer.endDate = offerData.end_date;
            this.offer.contactInfoTitleEn = offerData.contact_info.en.title;
            this.offer.contactInfoTitleBn = offerData.contact_info.bn.title;

            this.offer.address1TitleEn =
              offerData.contact_info.en.contacts.address[0]?.title || "";
            this.offer.address1TitleBn =
              offerData.contact_info.bn.contacts.address[0]?.title || "";

            this.offer.address2TitleEn =
              offerData.contact_info.en.contacts.address[1]?.title || "";
            this.offer.address2TitleBn =
              offerData.contact_info.bn.contacts.address[1]?.title || "";
            this.offer.address3Icon =
              offerData.contact_info.en.contacts.address[2]?.icon || "";
            this.offer.address3TitleEn =
              offerData.contact_info.en.contacts.address[2]?.title || "";
            this.offer.address3TitleBn =
              offerData.contact_info.bn.contacts.address[2]?.title || "";
            this.offer.weight = offerData.weight;
            this.offer.status = !!offerData.status;
            this.offer.discountAmount = offerData.discount_amount || 0;
            this.offer.discountType = offerData.discount_type || "percentage";
            this.offer.facilities = offerData.facility || [];
            this.offer.coverImages = [];
            offerData.coverImages.data.forEach((image, index) => {
              this.offer.coverImages.push({
                icon: image.image,
              });
            });
          } else {
            if (response.status_code === 401) {
              this.$store.dispatch("logout");
              this.$router.push("/login");
            }
          }
        })
        .catch(() => {
          this.confirmLoading = false;
        })
        .finally(() => {
          this.confirmLoading = false;
        });
    },
    onCoverImageFileChange(e, index) {
      this.errorMsg = "";
      if (this.isEditMode) {
        this.offer.updatedCoverImages[index] = e.target.files[0];
      } else {
        this.offer.coverImages[index].icon = e.target.files[0];
      }
    },

    onStartDateChange(value, dateString) {
      this.offer.startDate = value.format("YYYY-MM-DD HH:mm:00");
    },
    onEndDateChange(value, dateString) {
      this.offer.endDate = value.format("YYYY-MM-DD HH:mm:00");
    },

    onSubmit() {
      if (this.isEditMode) {
        this.updateOffer();
      } else {
        this.createOffer();
      }
    },

    createOffer() {
      this.confirmLoading = true;
      this.errorMsg = "";
      if (this.offer.category_id.length === 0) {
        this.confirmLoading = false;
        message.error({
          style: {
            marginTop: "20vh",
          },
          class: "custom-class",
          content: () => "Please provide category",
        });
        return;
      }
      if (this.offer.partner_id.length === 0) {
        this.confirmLoading = false;
        message.error({
          style: {
            marginTop: "20vh",
          },
          class: "custom-class",
          content: () => "Please provide partner",
        });
        return;
      }
      if (this.offer.package_id.length === 0) {
        this.confirmLoading = false;
        message.error({
          style: {
            marginTop: "20vh",
          },
          class: "custom-class",
          content: () => "Please provide package",
        });
        return;
      }
      if (this.offer.titleEn.length === 0) {
        this.confirmLoading = false;
        message.error({
          style: {
            marginTop: "20vh",
          },
          class: "custom-class",
          content: () => "Please provide title in English",
        });
        return;
      }
      if (this.offer.titleBn.length === 0) {
        this.confirmLoading = false;
        message.error({
          style: {
            marginTop: "20vh",
          },
          class: "custom-class",
          content: () => "Please provide title in Bangla",
        });
        return;
      }
      if (this.offer.descriptionEn.length === 0) {
        this.confirmLoading = false;
        message.error({
          content: () => "Please provide description in English",
          class: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });
        return;
      }
      if (this.offer.descriptionBn.length === 0) {
        this.confirmLoading = false;
        message.error({
          content: () => "Please provide description in Bangla",
          class: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });
        return;
      }

      if (_.isNull(this.offer.startDate)) {
        this.confirmLoading = false;
        message.error({
          content: () => "Please provide start date",
          class: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });
        return;
      }
      if (_.isNull(this.offer.endDate)) {
        this.confirmLoading = false;
        message.error({
          content: () => "Please provide end date",
          class: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });
        return;
      }

      if (this.offer.contactInfoTitleEn.length === 0) {
        this.confirmLoading = false;
        message.error({
          content: () => "Please provide contact information title in English",
          class: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });
        return;
      }
      if (this.offer.contactInfoTitleBn.length === 0) {
        this.confirmLoading = false;
        message.error({
          content: () => "Please provide contact information title in Bangla",
          class: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });
        return;
      }

      if (this.offer.address1TitleEn.length === 0) {
        this.confirmLoading = false;
        message.error({
          content: () => "Please provide address in English",
          class: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });
        return;
      }
      if (this.offer.address1TitleBn.length === 0) {
        this.confirmLoading = false;
        message.error({
          content: () => "Please provide address in Bangla",
          class: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });
        return;
      }
      if (this.offer.address2TitleEn.length === 0) {
        this.confirmLoading = false;
        message.error({
          content: () => "Please provide phone number in English",
          class: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });
        return;
      }
      if (this.offer.address2TitleBn.length === 0) {
        this.confirmLoading = false;
        message.error({
          content: () => "Please provide phone number in Bangla",
          class: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });
        return;
      }
      if (this.offer.address3TitleEn.length === 0) {
        this.confirmLoading = false;
        message.error({
          content: () => "Please provide email",
          class: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });
        return;
      }

      let formData = new FormData();
      let configHeader = {
        "content-type": "multipart/form-data",
        Authorization: "Bearer " + this.$store.state.authUser?.token,
      };
      formData.append("category_id", this.offer.category_id);
      formData.append("partner_id", this.offer.partner_id);
      formData.append("package_id", this.offer.package_id);
      formData.append("offer_title[en]", this.offer.titleEn);
      formData.append("offer_title[bn]", this.offer.titleBn);
      formData.append("description[en]", this.offer.descriptionEn);
      formData.append("description[bn]", this.offer.descriptionBn);
      formData.append("information[en][title]", this.offer.informationTitleEn);
      formData.append(
        "information[en][details]",
        this.offer.informationDetailsEn
      );
      formData.append("information[bn][title]", this.offer.informationTitleBn);
      formData.append(
        "information[bn][details]",
        this.offer.informationDetailsBn
      );
      formData.append("avail_offer[en][title]", this.offer.availOfferTitleEn);
      formData.append(
        "avail_offer[en][details]",
        this.offer.availOfferDetailsEn
      );
      formData.append("avail_offer[bn][title]", this.offer.availOfferTitleBn);
      formData.append(
        "avail_offer[bn][details]",
        this.offer.availOfferDetailsBn
      );
      formData.append(
        "term_and_condition[en]",
        this.offer.termsAndConditionsEn
      );
      formData.append(
        "term_and_condition[bn]",
        this.offer.termsAndConditionsBn
      );
      formData.append("contact_info[en][title]", this.offer.contactInfoTitleEn);
      formData.append("contact_info[bn][title]", this.offer.contactInfoTitleBn);

      formData.append(
        "contact_info[en][address][0][title]",
        this.offer.address1TitleEn
      );

      formData.append(
        "contact_info[en][address][1][title]",
        this.offer.address2TitleEn
      );

      formData.append(
        "contact_info[en][address][2][title]",
        this.offer.address3TitleEn
      );

      formData.append(
        "contact_info[bn][address][0][title]",
        this.offer.address1TitleBn
      );

      formData.append(
        "contact_info[bn][address][1][title]",
        this.offer.address2TitleBn
      );

      formData.append(
        "contact_info[bn][address][2][title]",
        this.offer.address3TitleEn
      );
      formData.append("weight", Number(this.offer.weight));
      formData.append("discount_amount", Number(this.offer.discountAmount));
      formData.append("discount_type", this.offer.discountType);
      formData.append("start_date", this.offer.startDate);
      formData.append("end_date", this.offer.endDate);
      formData.append("status", Number(this.offer.status));
      formData.append("facility", this.offer.facilities);
      this.offer.coverImages.forEach((image) => {
        formData.append("coverImages[]", image.icon);
      });

      sendRequest("post", "admin/offers", formData, configHeader)
        .then((response) => {
          this.confirmLoading = false;
          if (response.success) {
            message.success({
              content: () => "Successfully Added",
              class: "custom-class",
              style: {
                marginTop: "20vh",
              },
            });
            this.$router.push("/offers");
          } else {
            message.error({
              content: () => response.message,
              class: "custom-class",
              style: {
                marginTop: "20vh",
              },
            });
          }
        })
        .catch(() => {
          this.confirmLoading = false;
        })
        .finally(() => {
          this.confirmLoading = false;
        });
    },

    updateOffer() {
      this.confirmLoading = true;
      this.errorMsg = "";
      if (this.offer.category_id.length === 0) {
        this.confirmLoading = false;
        message.error({
          style: {
            marginTop: "20vh",
          },
          class: "custom-class",
          content: () => "Please provide category",
        });
        return;
      }
      if (this.offer.partner_id.length === 0) {
        this.confirmLoading = false;
        message.error({
          style: {
            marginTop: "20vh",
          },
          class: "custom-class",
          content: () => "Please provide partner",
        });
        return;
      }
      if (this.offer.package_id.length === 0) {
        this.confirmLoading = false;
        message.error({
          style: {
            marginTop: "20vh",
          },
          class: "custom-class",
          content: () => "Please provide package",
        });
        return;
      }
      if (this.offer.titleEn.length === 0) {
        this.confirmLoading = false;
        message.error({
          style: {
            marginTop: "20vh",
          },
          class: "custom-class",
          content: () => "Please provide title in English",
        });
        return;
      }
      if (this.offer.titleBn.length === 0) {
        this.confirmLoading = false;
        message.error({
          style: {
            marginTop: "20vh",
          },
          class: "custom-class",
          content: () => "Please provide title in Bangla",
        });
        return;
      }
      if (this.offer.descriptionEn.length === 0) {
        this.confirmLoading = false;
        message.error({
          content: () => "Please provide description in English",
          class: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });
        return;
      }
      if (this.offer.descriptionBn.length === 0) {
        this.confirmLoading = false;
        message.error({
          content: () => "Please provide description in Bangla",
          class: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });
        return;
      }
      if (this.offer.descriptionBn.length === 0) {
        this.confirmLoading = false;
        message.error({
          content: () => "Please provide description in Bangla",
          class: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });
        return;
      }
      if (_.isNull(this.offer.startDate)) {
        this.confirmLoading = false;
        message.error({
          content: () => "Please provide start date",
          class: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });
        return;
      }
      if (_.isNull(this.offer.endDate)) {
        this.confirmLoading = false;
        message.error({
          content: () => "Please provide end date",
          class: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });
        return;
      }

      if (this.offer.contactInfoTitleEn.length === 0) {
        this.confirmLoading = false;
        message.error({
          content: () => "Please provide contact information title in English",
          class: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });
        return;
      }
      if (this.offer.contactInfoTitleBn.length === 0) {
        this.confirmLoading = false;
        message.error({
          content: () => "Please provide contact information title in Bangla",
          class: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });
        return;
      }

      if (this.offer.address1TitleEn.length === 0) {
        this.confirmLoading = false;
        message.error({
          content: () => "Please provide address in English",
          class: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });
        return;
      }
      if (this.offer.address1TitleBn.length === 0) {
        this.confirmLoading = false;
        message.error({
          content: () => "Please provide address in Bangla",
          class: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });
        return;
      }
      if (this.offer.address2TitleEn.length === 0) {
        this.confirmLoading = false;
        message.error({
          content: () => "Please provide phone number in English",
          class: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });
        return;
      }
      if (this.offer.address2TitleBn.length === 0) {
        this.confirmLoading = false;
        message.error({
          content: () => "Please provide phone number in Bangla",
          class: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });
        return;
      }
      if (this.offer.address3TitleEn.length === 0) {
        this.confirmLoading = false;
        message.error({
          content: () => "Please provide email",
          class: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });
        return;
      }

      let formData = new FormData();
      let configHeader = {
        "content-type": "multipart/form-data",
        Authorization: "Bearer " + this.$store.state.authUser?.token,
      };
      formData.append("category_id", this.offer.category_id);
      formData.append("partner_id", this.offer.partner_id);
      formData.append("package_id", this.offer.package_id);
      formData.append("offer_title[en]", this.offer.titleEn);
      formData.append("offer_title[bn]", this.offer.titleBn);
      formData.append("description[en]", this.offer.descriptionEn);
      formData.append("description[bn]", this.offer.descriptionBn);
      formData.append("information[en][title]", this.offer.informationTitleEn);
      formData.append(
        "information[en][details]",
        this.offer.informationDetailsEn
      );
      formData.append("information[bn][title]", this.offer.informationTitleBn);
      formData.append(
        "information[bn][details]",
        this.offer.informationDetailsBn
      );
      formData.append("avail_offer[en][title]", this.offer.availOfferTitleEn);
      formData.append(
        "avail_offer[en][details]",
        this.offer.availOfferDetailsEn
      );
      formData.append("avail_offer[bn][title]", this.offer.availOfferTitleBn);
      formData.append(
        "avail_offer[bn][details]",
        this.offer.availOfferDetailsBn
      );
      formData.append(
        "terms_and_condition[en]",
        this.offer.termsAndConditionsEn
      );
      formData.append(
        "terms_and_condition[bn]",
        this.offer.termsAndConditionsBn
      );
      formData.append("contact_info[en][title]", this.offer.contactInfoTitleEn);
      formData.append("contact_info[bn][title]", this.offer.contactInfoTitleBn);

      formData.append(
        "contact_info[en][address][0][title]",
        this.offer.address1TitleEn
      );

      formData.append(
        "contact_info[en][address][1][title]",
        this.offer.address2TitleEn
      );

      formData.append(
        "contact_info[en][address][2][title]",
        this.offer.address3TitleEn
      );

      formData.append(
        "contact_info[bn][address][0][title]",
        this.offer.address1TitleBn
      );

      formData.append(
        "contact_info[bn][address][1][title]",
        this.offer.address2TitleBn
      );

      formData.append(
        "contact_info[bn][address][2][title]",
        this.offer.address3TitleBn
      );
      formData.append("weight", Number(this.offer.weight));
      formData.append("discount_amount", Number(this.offer.discountAmount));
      formData.append("discount_type", this.offer.discountType);
      formData.append("start_date", this.offer.startDate);
      formData.append("end_date", this.offer.endDate);
      formData.append("status", Number(this.offer.status));
      formData.append("facility", this.offer.facilities);
      this.offer.coverImages.forEach((image, index) => {
        if (
          this.offer.updatedCoverImages.length > 0 &&
          this.offer.updatedCoverImages[index] !== undefined
        ) {
          formData.append(
            `coverImages[${index}]`,
            this.offer.updatedCoverImages[index]
          );
        } else {
          formData.append(`coverImages[${index}]`, image.icon);
        }
      });

      sendRequest(
        "post",
        `admin/offers/${this.offer.id}`,
        formData,
        configHeader
      )
        .then((response) => {
          this.confirmLoading = false;
          if (response.success) {
            message.success({
              content: () => "Successfully Updated",
              class: "custom-class",
              style: {
                marginTop: "20vh",
              },
            });
            this.$router.push("/offers");
          } else {
            message.error({
              content: () => response.message,
              class: "custom-class",
              style: {
                marginTop: "20vh",
              },
            });
          }
        })
        .catch(() => {
          this.confirmLoading = false;
        })
        .finally(() => {
          this.confirmLoading = false;
        });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
  computed: {
    partnerfilterList: function () {
      return this.partnerOptions;
    },
  },
};
</script>
