export default function adminAuth({next, auth}){
    const adminRoles = ['super-admin', 'developer','admin']

        if(! adminRoles.includes(auth.role)) {
            return next({
                name: '403'
            });
        }

    return next();
}