<template>
  <div>
    <a-row :gutter="24" type="flex">
      <a-col :span="24" class="mb-24">
        <a-card
          :bordered="false"
          class="header-solid h-full"
          :bodyStyle="{ padding: 0 }"
        >
          <template #title>
            <a-row type="flex" align="middle">
              <a-col :span="24" :md="12" class="top-left-header">
                <h5 class="font-semibold m-0">
                  Special Offer Create
                  <a-spin v-if="confirmLoading" />
                </h5>
              </a-col>
            </a-row>
          </template>
          <div class="new-upload-data" style="padding-left: 10px">
            <div style="display: flex; justify-content: space-between">
              <div style="flex: 1">
                <h4 style="font-weight: bold">
                  Category <span style="color: red">*</span>
                </h4>
                <a-select
                  v-model:value="specialOffer.category_id"
                  placeholder="Select a category"
                  style="width: 100%"
                  :options="categoryOptions"
                  show-search
                  :filter-option="filterOption"
                >
                </a-select>
              </div>
              <div style="flex: 1; margin-left: 100px">
                <h4 style="font-weight: bold">
                  Offer Status <span style="color: red">*</span>
                </h4>
                <a-switch v-model:checked="specialOffer.status" />
              </div>
            </div>
            <div>
              <h4 style="font-weight: bold">
                Name <span style="color: red">*</span>
              </h4>

              <a-row>
                <a-input
                  v-model="specialOffer.nameEn"
                  placeholder="Title (English)"
                />
                <a-input
                  v-model="specialOffer.nameBn"
                  placeholder="Title (Bangla)"
                  style="margin-top: 4px"
                />
              </a-row>
            </div>

            <div>
              <h4 style="font-weight: bold">
                Title <span style="color: red">*</span>
              </h4>
              <a-row>
                <a-input
                  v-model="specialOffer.titleBn"
                  placeholder="Offer Title (Bangla)"
                />
                <a-input
                  v-model="specialOffer.titleEn"
                  placeholder="Offer Title (English)"
                  style="margin-top: 4px"
                />
              </a-row>
            </div>

            <div>
              <h4 style="font-weight: bold">
                Offer Title <span style="color: red">*</span>
              </h4>
              <a-row>
                <a-input
                  v-model="specialOffer.offerTitleBn"
                  placeholder="Offer Title (Bangla)"
                />
                <a-input
                  v-model="specialOffer.offerTitleEn"
                  placeholder="Offer Title (English)"
                  style="margin-top: 4px"
                />
              </a-row>
            </div>

            <div>
              <h4 style="font-weight: bold">
                Offer Details <span style="color: red">*</span>
              </h4>

              <a-row>
                <a-input
                  v-model="specialOffer.offerDetailsEn"
                  placeholder="Offer Details (English)"
                />
                <a-input
                  v-model="specialOffer.offerDetailsBn"
                  placeholder="Offer Details (Bangla)"
                  style="margin-top: 4px"
                />
              </a-row>
            </div>
            <div>
              <h4 style="font-weight: bold">
                Offer Description Title <span style="color: red">*</span>
              </h4>
              <a-row>
                <a-input
                  v-model="specialOffer.descriptionTitleEn"
                  placeholder="Description Title (English)"
                />
                <a-input
                  v-model="specialOffer.descriptionTitleBn"
                  placeholder="Description Title (Bangla)"
                  style="margin-top: 4px"
                />
              </a-row>
            </div>

            <div>
              <h4 style="font-weight: bold">
                Offer Description Details <span style="color: red">*</span>
              </h4>
              <a-input
                v-model="specialOffer.descriptionDetailsEn"
                placeholder="Description Details (English)"
              />
              <a-input
                v-model="specialOffer.descriptionDetailsBn"
                placeholder="Description Details (Bangla)"
                style="margin-top: 4px"
              />
            </div>

            <div>
              <h4 style="font-weight: bold">
                Terms and Condition <span style="color: red">*</span>
              </h4>
              <a-row>
                <a-input
                  v-model="specialOffer.termsAndConditionsEn"
                  placeholder="Terms and Condition (English)"
                />
                <a-input
                  v-model="specialOffer.termsAndConditionsBn"
                  placeholder="Terms and Condition (Bangla)"
                  style="margin-top: 4px"
                />
              </a-row>
            </div>

            <div>
              <h4>Offer Validity <span style="color: red">*</span></h4>
              <div style="display: flex">
                <div style="margin-right: 20px">
                  <div>Start Date</div>
                  <a-date-picker
                    style="width: 50%"
                    v-model="specialOffer.startDate"
                    placeholder="Start Date"
                    show-time
                    format="YYYY-MM-DD HH:mm:ss"
                    @change="onStartDateChange"
                  />
                </div>
                <div>
                  <div>End Date</div>
                  <a-date-picker
                    style="width: 50%"
                    v-model="specialOffer.endDate"
                    placeholder="End Date"
                    show-time
                    format="YYYY-MM-DD HH:mm"
                    @change="onEndDateChange"
                  />
                </div>
              </div>
            </div>

            <div style="display: flex">
              <div style="flex: 1">
                <h6 style="margin-right: 16px">Weight</h6>
                <a-input-number
                  style="width: 50%"
                  v-model="specialOffer.weight"
                  placeholder="Weight"
                />
              </div>
              <div style="flex: 1">
                <h6 style="margin-right: 16px">Discount Amount</h6>
                <a-input-number
                  style="width: 50%"
                  v-model="specialOffer.discountAmount"
                  placeholder="Discount Amount"
                />
                <a-select
                  v-model:value="specialOffer.discountType"
                  placeholder="Select a discount Type"
                  :options="[
                    { value: 'percentage', label: 'Percentage' },
                    { value: 'fixed', label: 'Fixed' },
                  ]"
                  @change="changeDiscountType"
                  style="margin-left: 5px"
                >
                </a-select>
              </div>
            </div>

            <div v-if="this.isEditMode" class="mt-5">
              <img
                :src="specialOffer.logo"
                :alt="specialOffer.nameEn"
                height="100"
                width="100"
              />
            </div>
            <div>
              <h4>Logo: <span style="color: red">*</span></h4>
              <a-input
                type="file"
                @change="onLogoFileChange"
                placeholder="Basic usage"
                accept="image/png, image/jpeg, image/jpg"
              />
              <small><i>Dimension should be 96 x 96</i></small>
            </div>
            <div>
              <h4>Cover Images: <span style="color: red">*</span></h4>
              <div class="new-upload-data" style="margin: 0 16px">
                <div v-for="(item, index) in this.specialOffer.coverImages">
                  <div v-if="isEditMode && item.icon.length > 0" class="mt-5">
                    <img
                      :src="item.icon"
                      :alt="`Cover image ${index + 1}`"
                      height="100"
                      width="100"
                    />
                  </div>
                  <label>Cover Image {{ index + 1 }}</label>
                  <a-row type="flex" justify="space-between">
                    <div style="width: 50%">
                      <a-input
                        type="file"
                        @change="onCoverImageFileChange($event, index)"
                        placeholder="Basic usage"
                        accept="image/png, image/jpeg, image/jpg"
                      />
                      <small
                        ><i>Minimum image dimension is 1080 x 1080</i></small
                      >
                    </div>
                    <a-row
                      v-if="specialOffer.coverImages.length > 1"
                      type="flex"
                    >
                      <a-col :span="2">
                        <a-button type="danger" @click="removeCoverImage(index)"
                          >Remove</a-button
                        >
                      </a-col>
                    </a-row>
                  </a-row>
                </div>
                <a-row type="flex" justify="start">
                  <a-col :span="2">
                    <a-button type="primary" @click="addCoverImage"
                      >Add Cover Image</a-button
                    >
                  </a-col>
                </a-row>
              </div>
            </div>
            <div v-if="errorMsg.length" class="ant-btn-danger mt-5">
              {{ errorMsg }}
            </div>
            <div class="mt-5 left-align-content">
              <a-button
                type="primary"
                @click="onSubmit"
                :loading="confirmLoading"
                >Submit</a-button
              >
            </div>
          </div>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { sendRequest } from "@/http/axios.method";
import { message } from "ant-design-vue";
import { VueEditor } from "vue2-editor";

export default {
  name: "special-offer-form",
  components: {
    VueEditor,
  },
  data() {
    return {
      config: {
        Authorization: "Bearer " + this.$store.state.authUser?.token,
      },
      categoryOptions: [],
      confirmLoading: false,
      specialOffer: {
        id: "",
        category_id: "",
        nameEn: "",
        nameBn: "",
        titleEn: "",
        titleBn: "",
        offerTitleEn: "",
        offerTitleBn: "",
        offerDetailsEn: "",
        offerDetailsBn: "",
        descriptionTitleEn: "",
        descriptionTitleBn: "",
        descriptionDetailsEn: "",
        descriptionDetailsBn: "",
        termsAndConditionsEn: "",
        termsAndConditionsBn: "",
        startDate: "",
        endDate: "",
        weight: "",
        discountAmount: "",
        discountType: "percentage",
        logo: "",
        updatedLogo: "",
        coverImages: [
          {
            icon: "",
          },
        ],
        updatedCoverImages: [],
        status: true,
      },
      logoContent: "",
      errorMsg: "",
    };
  },
  created() {
    if (this.isEditMode) {
      const specialOfferId = this.$route.params.id;
      this.fetchSpecialOfferById(specialOfferId);
    }
    this.getCategoryList();
  },
  methods: {
    removeCoverImage(index) {
      if (this.specialOffer.coverImages.length > 1) {
        this.specialOffer.coverImages.splice(index, 1);
      }
    },
    addCoverImage() {
      this.specialOffer.coverImages.push({
        icon: "",
      });
    },
    getCategoryList() {
      this.confirmLoading = true;
      sendRequest("get", "admin/categories/active", {}, this.config)
        .then((response) => {
          this.confirmLoading = false;
          if (response.success) {
            let categoryList = response.data;
            this.categoryOptions = categoryList.map(function (category, label) {
              return {
                value: category.id,
                label: category.name,
              };
            });
          } else {
            if (response.status_code === 401) {
              this.$store.dispatch("logout");
              this.$router.push("/login");
            }
          }
        })
        .catch(() => {
          this.confirmLoading = false;
        })
        .finally(() => {
          this.confirmLoading = false;
        });
    },
    fetchSpecialOfferById(specialOfferId) {
      this.confirmLoading = true;
      sendRequest(
        "get",
        "admin/special-offers/" + specialOfferId,
        {},
        this.config
      )
        .then((response) => {
          this.confirmLoading = false;
          if (response.success) {
            let specialOfferData = response.data;
            this.specialOffer.id = specialOfferData.id;
            this.specialOffer.category_id = specialOfferData.category_id;
            this.specialOffer.nameEn = specialOfferData.name.en;
            this.specialOffer.nameBn = specialOfferData.name.bn;
            this.specialOffer.titleEn = specialOfferData.title.en;
            this.specialOffer.titleBn = specialOfferData.title.bn;
            this.specialOffer.offerTitleEn = specialOfferData.offer_title.en;
            this.specialOffer.offerTitleBn = specialOfferData.offer_title.bn;
            this.specialOffer.offerDetailsEn =
              specialOfferData.offer_details.en;
            this.specialOffer.offerDetailsBn =
              specialOfferData.offer_details.bn;
            this.specialOffer.descriptionTitleEn =
              specialOfferData.description.en.title;
            this.specialOffer.descriptionTitleBn =
              specialOfferData.description.bn.title;
            this.specialOffer.descriptionDetailsEn =
              specialOfferData.description.en.details;
            this.specialOffer.descriptionDetailsBn =
              specialOfferData.description.bn.details;
            this.specialOffer.termsAndConditionsEn =
              specialOfferData.term_and_condition.en;
            this.specialOffer.termsAndConditionsBn =
              specialOfferData.term_and_condition.bn;
            this.specialOffer.startDate = specialOfferData.start_date;
            this.specialOffer.endDate = specialOfferData.end_date;
            this.specialOffer.weight = specialOfferData.weight;
            this.specialOffer.status = !!specialOfferData.status;
            this.specialOffer.logo = specialOfferData.logo;
            this.specialOffer.discountAmount =
              specialOfferData.discount_amount || 0;
            this.specialOffer.discountType =
              specialOfferData.discount_type || "percentage";
            if (specialOfferData.coverImages.data.length > 0) {
              this.specialOffer.coverImages = [];
              specialOfferData.coverImages.data.forEach((image) => {
                this.specialOffer.coverImages.push({
                  icon: image.image,
                });
              });
            }
          } else {
            if (response.status_code === 401) {
              this.$store.dispatch("logout");
              this.$router.push("/login");
            }
          }
        })
        .catch(() => {
          this.confirmLoading = false;
        })
        .finally(() => {
          this.confirmLoading = false;
        });
    },
    onCoverImageFileChange(e, index) {
      this.errorMsg = "";
      if (this.isEditMode) {
        this.specialOffer.updatedCoverImages[index] = e.target.files[0];
      } else {
        this.specialOffer.coverImages[index].icon = e.target.files[0];
      }
    },
    onLogoFileChange(e) {
      this.errorMsg = "";
      if (this.isEditMode) {
        this.specialOffer.updatedLogo = e.target.files[0];
      } else {
        this.specialOffer.logo = e.target.files[0];
      }
    },
    onStartDateChange(value, dateString) {
      this.specialOffer.startDate = value.format("YYYY-MM-DD HH:mm");
    },
    onEndDateChange(value, dateString) {
      this.specialOffer.endDate = value.format("YYYY-MM-DD HH:mm");
    },

    onSubmit() {
      if (this.isEditMode) {
        this.updateSpecialOffer();
      } else {
        this.createSpecialOffer();
      }
    },

    createSpecialOffer() {
      this.confirmLoading = true;
      this.errorMsg = "";
      if (this.specialOffer.category_id.length === 0) {
        this.confirmLoading = false;
        message.error({
          style: {
            marginTop: "20vh",
          },
          class: "custom-class",
          content: () => "Please provide category",
        });
        return;
      }
      if (this.specialOffer.nameEn.length === 0) {
        this.confirmLoading = false;
        message.error({
          style: {
            marginTop: "20vh",
          },
          class: "custom-class",
          content: () => "Please provide name in English",
        });
        return;
      }
      if (this.specialOffer.nameBn.length === 0) {
        this.confirmLoading = false;
        message.error({
          style: {
            marginTop: "20vh",
          },
          class: "custom-class",
          content: () => "Please provide name in Bangla",
        });
        return;
      }
      if (this.specialOffer.titleEn.length === 0) {
        this.confirmLoading = false;
        message.error({
          style: {
            marginTop: "20vh",
          },
          class: "custom-class",
          content: () => "Please provide title in English",
        });
        return;
      }
      if (this.specialOffer.titleBn.length === 0) {
        this.confirmLoading = false;
        message.error({
          style: {
            marginTop: "20vh",
          },
          class: "custom-class",
          content: () => "Please provide title in Bangla",
        });
        return;
      }
      if (this.specialOffer.offerTitleEn.length === 0) {
        this.confirmLoading = false;
        message.error({
          style: {
            marginTop: "20vh",
          },
          class: "custom-class",
          content: () => "Please provide offer title in English",
        });
        return;
      }
      if (this.specialOffer.offerTitleBn.length === 0) {
        this.confirmLoading = false;
        message.error({
          style: {
            marginTop: "20vh",
          },
          class: "custom-class",
          content: () => "Please provide offer title in Bangla",
        });
        return;
      }
      if (this.specialOffer.offerDetailsEn.length === 0) {
        this.confirmLoading = false;
        message.error({
          style: {
            marginTop: "20vh",
          },
          class: "custom-class",
          content: () => "Please provide offer details in English",
        });
        return;
      }
      if (this.specialOffer.offerDetailsBn.length === 0) {
        this.confirmLoading = false;
        message.error({
          style: {
            marginTop: "20vh",
          },
          class: "custom-class",
          content: () => "Please provide offer details in Bangla",
        });
        return;
      }
      if (this.specialOffer.descriptionTitleEn.length === 0) {
        this.confirmLoading = false;
        message.error({
          content: () => "Please provide description title in English",
          class: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });
        return;
      }
      if (this.specialOffer.descriptionTitleBn.length === 0) {
        this.confirmLoading = false;
        message.error({
          content: () => "Please provide description title in Bangla",
          class: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });
        return;
      }
      if (this.specialOffer.descriptionDetailsEn.length === 0) {
        this.confirmLoading = false;
        message.error({
          content: () => "Please provide description details in English",
          class: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });
        return;
      }
      if (this.specialOffer.descriptionDetailsBn.length === 0) {
        this.confirmLoading = false;
        message.error({
          content: () => "Please provide description details in Bangla",
          class: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });
        return;
      }
      if (this.specialOffer.termsAndConditionsEn.length === 0) {
        this.confirmLoading = false;
        message.error({
          content: () => "Please provide term and condition in English",
          class: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });
        return;
      }
      if (this.specialOffer.termsAndConditionsBn.length === 0) {
        this.confirmLoading = false;
        message.error({
          content: () => "Please provide term and condition in Bangla",
          class: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });
        return;
      }
      if (this.specialOffer.startDate.length === 0) {
        this.confirmLoading = false;
        message.error({
          content: () => "Please provide start date",
          class: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });
        return;
      }
      if (this.specialOffer.endDate.length === 0) {
        this.confirmLoading = false;
        message.error({
          content: () => "Please provide end date",
          class: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });
        return;
      }
      if (this.specialOffer.logo.length === 0) {
        this.confirmLoading = false;
        message.error({
          content: () => "Please provide logo.",
          class: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });
        return;
      }

      let formData = new FormData();
      let configHeader = {
        "content-type": "multipart/form-data",
        Authorization: "Bearer " + this.$store.state.authUser?.token,
      };
      formData.append("category_id", this.specialOffer.category_id);
      formData.append("name[en]", this.specialOffer.nameEn);
      formData.append("name[bn]", this.specialOffer.nameBn);
      formData.append("title[en]", this.specialOffer.titleEn);
      formData.append("title[bn]", this.specialOffer.titleBn);
      formData.append("offer_title[en]", this.specialOffer.offerTitleEn);
      formData.append("offer_title[bn]", this.specialOffer.offerTitleBn);
      formData.append("offer_details[en]", this.specialOffer.offerDetailsEn);
      formData.append("offer_details[bn]", this.specialOffer.offerDetailsBn);
      formData.append(
        "description[en][title]",
        this.specialOffer.descriptionTitleEn
      );
      formData.append(
        "description[bn][title]",
        this.specialOffer.descriptionTitleBn
      );
      formData.append(
        "description[en][details]",
        this.specialOffer.descriptionDetailsEn
      );
      formData.append(
        "description[bn][details]",
        this.specialOffer.descriptionDetailsBn
      );
      formData.append(
        "term_and_condition[en]",
        this.specialOffer.termsAndConditionsEn
      );
      formData.append(
        "term_and_condition[bn]",
        this.specialOffer.termsAndConditionsBn
      );
      formData.append("weight", Number(this.specialOffer.weight));
      formData.append(
        "discount_amount",
        Number(this.specialOffer.discountAmount)
      );
      formData.append("discount_type", this.specialOffer.discountType);
      formData.append("start_date", this.specialOffer.startDate);
      formData.append("end_date", this.specialOffer.endDate);
      formData.append("status", Number(this.specialOffer.status));
      formData.append("logo", this.specialOffer.logo);
      this.specialOffer.coverImages.forEach((image) => {
        formData.append("coverImages[]", image.icon);
      });

      sendRequest("post", "admin/special-offers", formData, configHeader)
        .then((response) => {
          if (response.success) {
            message.success({
              content: () => "Successfully Added",
              class: "custom-class",
              style: {
                marginTop: "20vh",
              },
            });
            this.confirmLoading = false;
            this.$router.push("/special-offers");
          } else {
            message.error({
              content: () => response.message,
              class: "custom-class",
              style: {
                marginTop: "20vh",
              },
            });
          }
        })
        .catch(() => {
          this.confirmLoading = false;
        })
        .finally(() => {
          this.confirmLoading = false;
        });
    },

    updateSpecialOffer() {
      this.confirmLoading = true;
      this.errorMsg = "";
      if (this.specialOffer.category_id.length === 0) {
        this.confirmLoading = false;
        message.error({
          style: {
            marginTop: "20vh",
          },
          class: "custom-class",
          content: () => "Please provide category",
        });
        return;
      }
      if (this.specialOffer.nameEn.length === 0) {
        this.confirmLoading = false;
        message.error({
          style: {
            marginTop: "20vh",
          },
          class: "custom-class",
          content: () => "Please provide name in English",
        });
        return;
      }
      if (this.specialOffer.nameBn.length === 0) {
        this.confirmLoading = false;
        message.error({
          style: {
            marginTop: "20vh",
          },
          class: "custom-class",
          content: () => "Please provide name in Bangla",
        });
        return;
      }
      if (this.specialOffer.titleEn.length === 0) {
        this.confirmLoading = false;
        message.error({
          style: {
            marginTop: "20vh",
          },
          class: "custom-class",
          content: () => "Please provide title in English",
        });
        return;
      }
      if (this.specialOffer.titleBn.length === 0) {
        this.confirmLoading = false;
        message.error({
          style: {
            marginTop: "20vh",
          },
          class: "custom-class",
          content: () => "Please provide title in Bangla",
        });
        return;
      }
      if (this.specialOffer.offerTitleEn.length === 0) {
        this.confirmLoading = false;
        message.error({
          style: {
            marginTop: "20vh",
          },
          class: "custom-class",
          content: () => "Please provide offer title in English",
        });
        return;
      }
      if (this.specialOffer.offerTitleBn.length === 0) {
        this.confirmLoading = false;
        message.error({
          style: {
            marginTop: "20vh",
          },
          class: "custom-class",
          content: () => "Please provide offer title in Bangla",
        });
        return;
      }
      if (this.specialOffer.offerDetailsEn.length === 0) {
        this.confirmLoading = false;
        message.error({
          style: {
            marginTop: "20vh",
          },
          class: "custom-class",
          content: () => "Please provide offer details in English",
        });
        return;
      }
      if (this.specialOffer.offerDetailsBn.length === 0) {
        this.confirmLoading = false;
        message.error({
          style: {
            marginTop: "20vh",
          },
          class: "custom-class",
          content: () => "Please provide offer details in Bangla",
        });
        return;
      }
      if (this.specialOffer.descriptionTitleEn.length === 0) {
        this.confirmLoading = false;
        message.error({
          content: () => "Please provide description title in English",
          class: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });
        return;
      }
      if (this.specialOffer.descriptionTitleBn.length === 0) {
        this.confirmLoading = false;
        message.error({
          content: () => "Please provide description title in Bangla",
          class: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });
        return;
      }
      if (this.specialOffer.descriptionDetailsEn.length === 0) {
        this.confirmLoading = false;
        message.error({
          content: () => "Please provide description details in English",
          class: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });
        return;
      }
      if (this.specialOffer.descriptionDetailsBn.length === 0) {
        this.confirmLoading = false;
        message.error({
          content: () => "Please provide description details in Bangla",
          class: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });
        return;
      }
      if (this.specialOffer.termsAndConditionsEn.length === 0) {
        this.confirmLoading = false;
        message.error({
          content: () => "Please provide term and condition in English",
          class: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });
        return;
      }
      if (this.specialOffer.termsAndConditionsBn.length === 0) {
        this.confirmLoading = false;
        message.error({
          content: () => "Please provide term and condition in Bangla",
          class: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });
        return;
      }
      if (this.specialOffer.startDate.length === 0) {
        this.confirmLoading = false;
        message.error({
          content: () => "Please provide start date",
          class: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });
        return;
      }
      if (this.specialOffer.endDate.length === 0) {
        this.confirmLoading = false;
        message.error({
          content: () => "Please provide end date",
          class: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });
        return;
      }
      if (
        this.specialOffer.logo.length === 0 &&
        !this.specialOffer.updatedLogo
      ) {
        this.confirmLoading = false;
        message.error({
          content: () => "Please provide logo.",
          class: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });
        return;
      }

      let formData = new FormData();
      let configHeader = {
        "content-type": "multipart/form-data",
        Authorization: "Bearer " + this.$store.state.authUser?.token,
      };
      formData.append("category_id", this.specialOffer.category_id);
      formData.append("name[en]", this.specialOffer.nameEn);
      formData.append("name[bn]", this.specialOffer.nameBn);
      formData.append("title[en]", this.specialOffer.titleEn);
      formData.append("title[bn]", this.specialOffer.titleBn);
      formData.append("offer_title[en]", this.specialOffer.offerTitleEn);
      formData.append("offer_title[bn]", this.specialOffer.offerTitleBn);
      formData.append("offer_details[en]", this.specialOffer.offerDetailsEn);
      formData.append("offer_details[bn]", this.specialOffer.offerDetailsBn);
      formData.append(
        "description[en][title]",
        this.specialOffer.descriptionTitleEn
      );
      formData.append(
        "description[bn][title]",
        this.specialOffer.descriptionTitleBn
      );
      formData.append(
        "description[en][details]",
        this.specialOffer.descriptionDetailsEn
      );
      formData.append(
        "description[bn][details]",
        this.specialOffer.descriptionDetailsBn
      );
      formData.append(
        "term_and_condition[en]",
        this.specialOffer.termsAndConditionsEn
      );
      formData.append(
        "term_and_condition[bn]",
        this.specialOffer.termsAndConditionsBn
      );
      formData.append("weight", Number(this.specialOffer.weight));
      formData.append(
        "discount_amount",
        Number(this.specialOffer.discountAmount)
      );
      formData.append("discount_type", this.specialOffer.discountType);
      formData.append("start_date", this.specialOffer.startDate);
      formData.append("end_date", this.specialOffer.endDate);
      formData.append("status", Number(this.specialOffer.status));
      if (this.specialOffer.updatedLogo) {
        formData.append("logo", this.specialOffer.updatedLogo);
      } else {
        formData.append("logo", this.specialOffer.logo);
      }
      this.specialOffer.coverImages.forEach((image, index) => {
        if (
          this.specialOffer.updatedCoverImages.length > 0 &&
          this.specialOffer.updatedCoverImages[index] !== undefined
        ) {
          formData.append(
            `coverImages[${index}]`,
            this.specialOffer.updatedCoverImages[index]
          );
        } else {
          formData.append(`coverImages[${index}]`, image.icon);
        }
      });

      sendRequest(
        "post",
        `admin/special-offers/${this.specialOffer.id}`,
        formData,
        configHeader
      )
        .then((response) => {
          if (response.success) {
            message.success({
              content: () => "Successfully Updated",
              class: "custom-class",
              style: {
                marginTop: "20vh",
              },
            });
            this.confirmLoading = false;
            this.$router.push("/special-offers");
          } else {
            message.error({
              content: () => response.message,
              class: "custom-class",
              style: {
                marginTop: "20vh",
              },
            });
          }
        })
        .catch(() => {
          this.confirmLoading = false;
        })
        .finally(() => {
          this.confirmLoading = false;
        });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
  computed: {
    isEditMode() {
      return this.$route.params.id !== undefined;
    },
  },
};
</script>
