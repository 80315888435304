<template>
  <!-- Main Sidebar -->
  <a-layout-sider
    collapsible
    class="sider-primary"
    breakpoint="lg"
    collapsed-width="0"
    width="250px"
    :collapsed="sidebarCollapsed"
    @collapse="$emit('toggleSidebar', !sidebarCollapsed)"
    :trigger="null"
    :class="[
      'ant-layout-sider-' + sidebarColor,
      'ant-layout-sider-' + sidebarTheme,
    ]"
    theme="light"
    :style="{ backgroundColor: 'transparent' }"
  >
    <div class="brand">
      <img src="/images/affinity-logo.png" alt="Deal Finder" />
      <span>Deal Finder</span>
    </div>
    <hr />

    <!-- Sidebar Navigation Menu -->
    <a-menu theme="light" mode="inline">
      <a-menu-item>
        <router-link to="/dashboard">
          <span class="icon">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V6C17 6.55228 16.5523 7 16 7H4C3.44772 7 3 6.55228 3 6V4Z"
                fill="#111827"
              />
              <path
                d="M3 10C3 9.44771 3.44772 9 4 9H10C10.5523 9 11 9.44771 11 10V16C11 16.5523 10.5523 17 10 17H4C3.44772 17 3 16.5523 3 16V10Z"
                fill="#111827"
              />
              <path
                d="M14 9C13.4477 9 13 9.44771 13 10V16C13 16.5523 13.4477 17 14 17H16C16.5523 17 17 16.5523 17 16V10C17 9.44771 16.5523 9 16 9H14Z"
                fill="#111827"
              />
            </svg>
          </span>
          <span class="label">Dashboard</span>
        </router-link>
      </a-menu-item>
      <a-layout-sider style="background: transparent">
        <a-menu mode="inline" :defaultOpenKeys="['Access Control']">
          <a-sub-menu
            key="Access Control"
            title="Access Control"
            style="font-weight: bold"
          >
            <a-menu-item v-if="developerRoles.includes(userRole)">
              <router-link to="/roles">
                <span class="icon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.009 10.5C12.2182 10.5 14.009 8.70914 14.009 6.5C14.009 4.29086 12.2182 2.5 10.009 2.5C7.79989 2.5 6.00903 4.29086 6.00903 6.5C6.00903 8.70914 7.79989 10.5 10.009 10.5Z"
                      fill="#6F737C"
                    />
                    <path
                      opacity="0.4"
                      d="M15.38 13.48C14.55 12.88 13.44 12.5 12 12.5H8C3.94 12.5 2.5 15.47 2.5 18.02C2.5 20.3 3.71 21.5 6 21.5H13.03C13.19 21.5 13.3199 21.39 13.3199 21.21C13.3199 21.15 13.26 21.03 13.24 20.99L12.72 20.1C12.49 19.71 12.4399 19.2401 12.5699 18.8101C12.6399 18.5901 12.75 18.38 12.91 18.21C12.92 18.2 13 18.112 13 18C13 17.92 12.96 17.84 12.91 17.79C12.65 17.49 12.5 17.11 12.5 16.71C12.5 16.39 12.6001 16.13 12.6801 15.97C12.7001 15.93 12.71 15.9 12.73 15.87L13.49 14.62C13.78 14.1 14.33 13.78 14.92 13.78C15.04 13.78 15.15 13.7899 15.26 13.8199C15.28 13.8199 15.3 13.83 15.33 13.83C15.45 13.83 15.51 13.75 15.5 13.65C15.5 13.57 15.42 13.51 15.38 13.48Z"
                      fill="#6F737C"
                    />
                    <path
                      d="M21.1801 18C21.1801 17.52 21.44 17.08 21.87 16.84L22 16.77L21.08 15.23L20.95 15.3C20.74 15.42 20.5 15.48 20.26 15.48C20.02 15.48 19.7799 15.42 19.5699 15.3C19.1499 15.06 18.89 14.62 18.89 14.15C18.89 14.07 18.82 14 18.73 14H17.21C17.12 14 17.0601 14.07 17.0601 14.15C17.0601 14.62 16.8 15.06 16.37 15.3C16.16 15.42 15.9201 15.48 15.6801 15.48C15.4401 15.48 15.2 15.42 14.99 15.3C14.92 15.26 14.83 15.29 14.79 15.36L14.02 16.64C14.01 16.66 14 16.69 14 16.71C14 16.76 14.03 16.81 14.08 16.84C14.5 17.08 14.76 17.52 14.76 18V18.03C14.76 18.49 14.49 18.92 14.08 19.16C14 19.2 13.98 19.29 14.02 19.36L14.78 20.64C14.81 20.69 14.87 20.72 14.92 20.72C14.95 20.72 14.97 20.71 14.99 20.7C15.42 20.46 15.95 20.46 16.37 20.7C16.78 20.94 17.05 21.3599 17.05 21.8199V21.85C17.05 21.93 17.12 22 17.21 22H18.74C18.82 22 18.89 21.93 18.89 21.85C18.89 21.38 19.1499 20.94 19.5699 20.7C19.7799 20.58 20.02 20.52 20.26 20.52C20.5 20.52 20.74 20.58 20.95 20.7C21.02 20.74 21.12 20.71 21.16 20.64L21.92 19.36C21.94 19.34 21.9399 19.31 21.9399 19.28C21.9399 19.23 21.92 19.19 21.87 19.16C21.44 18.92 21.1801 18.48 21.1801 18ZM18.02 19.14C17.39 19.14 16.87 18.63 16.87 18C16.87 17.37 17.38 16.86 18.01 16.86H18.02C18.65 16.86 19.17 17.37 19.17 18C19.17 18.63 18.65 19.14 18.02 19.14Z"
                      fill="#6F737C"
                    />
                  </svg>
                </span>
                <span class="label">Roles</span>
              </router-link>
            </a-menu-item>
            <a-menu-item v-if="developerRoles.includes(userRole)">
              <router-link to="/permissions">
                <span class="icon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.009 10.5C12.2182 10.5 14.009 8.70914 14.009 6.5C14.009 4.29086 12.2182 2.5 10.009 2.5C7.79989 2.5 6.00903 4.29086 6.00903 6.5C6.00903 8.70914 7.79989 10.5 10.009 10.5Z"
                      fill="#6F737C"
                    />
                    <path
                      opacity="0.4"
                      d="M15.17 13.49C15.17 13.61 15.1139 13.719 14.9399 13.78C14.8299 13.81 14.71 13.85 14.58 13.89C13.94 14.08 13.5 14.67 13.5 15.33V17.5601C13.5 18.9301 13.93 20.07 14.58 20.98C14.603 21.012 14.67 21.0999 14.67 21.1899C14.67 21.3399 14.581 21.482 14.34 21.49C14.23 21.5 14.12 21.5 14 21.5H6C3.71 21.5 2.5 20.3 2.5 18.02C2.5 15.47 3.94 12.5 8 12.5H12C13.21 12.5 14.18 12.76 14.95 13.2C14.99 13.22 15.069 13.271 15.08 13.28C15.137 13.328 15.17 13.41 15.17 13.49Z"
                      fill="#6F737C"
                    />
                    <path
                      d="M21.8571 15.275C20.1431 14.831 19.2861 14.444 18.4351 14C17.5711 14.444 16.715 14.831 15 15.333C15 15.788 15 17.0811 15 17.5551C15 20.2221 17.143 21.555 18.429 21.999C19.715 21.555 21.858 20.2211 21.858 17.5551C21.857 17.0651 21.8571 15.753 21.8571 15.275Z"
                      fill="#6F737C"
                    />
                  </svg>
                </span>
                <span class="label">Permissions</span>
              </router-link>
            </a-menu-item>
            <a-menu-item v-if="adminRoles.includes(userRole)">
              <router-link to="/user-list">
                <span class="icon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.627 8.75195C19.323 9.70695 18.5431 10.482 17.5861 10.781C16.0381 11.265 14.566 10.601 13.83 9.43201C13.772 9.34001 13.776 9.22403 13.829 9.12903C14.264 8.35303 14.511 7.457 14.511 6.5C14.511 6.168 14.482 5.84398 14.424 5.52698C14.405 5.42298 14.437 5.31497 14.518 5.24597C15.073 4.77497 15.791 4.5 16.561 4.5C18.677 4.5 20.327 6.55095 19.627 8.75195Z"
                      fill="#6F737C"
                    />
                    <path
                      opacity="0.4"
                      d="M22.5 16.6801C22.5 18.5001 21.49 19.5 19.66 19.5H18.2C18.04 19.5 17.907 19.371 17.907 19.201C17.907 19.161 17.91 19.124 17.92 19.08C17.98 18.75 18 18.39 18 18.02C18 16.1 17.35 14.1699 15.95 12.8199C15.94 12.8099 15.93 12.8 15.92 12.79C15.86 12.74 15.83 12.66 15.83 12.58C15.83 12.41 15.96 12.28 16.13 12.28H18.11C21.35 12.28 22.5 14.6501 22.5 16.6801Z"
                      fill="#6F737C"
                    />
                    <path
                      d="M9.01001 10.5C11.2191 10.5 13.01 8.70914 13.01 6.5C13.01 4.29086 11.2191 2.5 9.01001 2.5C6.80087 2.5 5.01001 4.29086 5.01001 6.5C5.01001 8.70914 6.80087 10.5 9.01001 10.5Z"
                      fill="#6F737C"
                    />
                    <path
                      opacity="0.4"
                      d="M16.5 18.02C16.5 20.3 15.29 21.5 13 21.5H5C2.71 21.5 1.5 20.3 1.5 18.02C1.5 15.47 2.94 12.5 7 12.5H11C15.06 12.5 16.5 15.47 16.5 18.02Z"
                      fill="#6F737C"
                    />
                  </svg>
                </span>
                <span class="label">User List</span>
              </router-link>
            </a-menu-item>
          </a-sub-menu>
        </a-menu>
      </a-layout-sider>
      <a-layout-sider style="background: transparent">
        <a-menu mode="inline" :defaultOpenKeys="['Referral']">
          <a-sub-menu key="Referral" title="Referral" style="font-weight: bold">
            <a-menu-item v-if="adminRoles.includes(userRole)">
              <router-link to="/referral-type">
                <span class="icon"
                  ><svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.4"
                      d="M19 12C19 13.1 19.9 14 21 14V17C21 19 20 20 18 20H6C4 20 3 19 3 17V14C4.1 14 5 13.1 5 12C5 10.9 4.1 10 3 10V7C3 5 4 4 6 4H18C20 4 21 5 21 7V10C19.9 10 19 10.9 19 12Z"
                      fill="#6F737C"
                    />
                    <path
                      d="M9.5 15.25C9.308 15.25 9.11597 15.177 8.96997 15.03C8.67697 14.737 8.67697 14.262 8.96997 13.969L13.97 8.96899C14.263 8.67599 14.738 8.67599 15.031 8.96899C15.324 9.26199 15.324 9.737 15.031 10.03L10.031 15.03C9.88401 15.177 9.692 15.25 9.5 15.25ZM10.52 9.5C10.52 8.948 10.073 8.5 9.52002 8.5H9.51001C8.95801 8.5 8.51501 8.948 8.51501 9.5C8.51501 10.052 8.96802 10.5 9.52002 10.5C10.072 10.5 10.52 10.052 10.52 9.5ZM15.52 14.5C15.52 13.948 15.073 13.5 14.52 13.5H14.51C13.958 13.5 13.515 13.948 13.515 14.5C13.515 15.052 13.968 15.5 14.52 15.5C15.072 15.5 15.52 15.052 15.52 14.5Z"
                      fill="#6F737C"
                    />
                  </svg>
                </span>
                <span class="label">Referral Type</span>
              </router-link>
            </a-menu-item>
            <a-menu-item v-if="adminRoles.includes(userRole)">
              <router-link to="/referral-settings">
                <span class="icon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.4"
                      d="M19 12C19 13.1 19.9 14 21 14V17C21 19 20 20 18 20H6C4 20 3 19 3 17V14C4.1 14 5 13.1 5 12C5 10.9 4.1 10 3 10V7C3 5 4 4 6 4H18C20 4 21 5 21 7V10C19.9 10 19 10.9 19 12Z"
                      fill="#6F737C"
                    />
                    <path
                      d="M9.5 15.25C9.308 15.25 9.11597 15.177 8.96997 15.03C8.67697 14.737 8.67697 14.262 8.96997 13.969L13.97 8.96899C14.263 8.67599 14.738 8.67599 15.031 8.96899C15.324 9.26199 15.324 9.737 15.031 10.03L10.031 15.03C9.88401 15.177 9.692 15.25 9.5 15.25ZM10.52 9.5C10.52 8.948 10.073 8.5 9.52002 8.5H9.51001C8.95801 8.5 8.51501 8.948 8.51501 9.5C8.51501 10.052 8.96802 10.5 9.52002 10.5C10.072 10.5 10.52 10.052 10.52 9.5ZM15.52 14.5C15.52 13.948 15.073 13.5 14.52 13.5H14.51C13.958 13.5 13.515 13.948 13.515 14.5C13.515 15.052 13.968 15.5 14.52 15.5C15.072 15.5 15.52 15.052 15.52 14.5Z"
                      fill="#6F737C"
                    />
                  </svg>
                </span>
                <span class="label">Referral Settings</span>
              </router-link>
            </a-menu-item>
            <a-menu-item v-if="adminRoles.includes(userRole)">
              <router-link to="/referral-withdraw">
                <span class="icon"
                  ><svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.4"
                      d="M19 12C19 13.1 19.9 14 21 14V17C21 19 20 20 18 20H6C4 20 3 19 3 17V14C4.1 14 5 13.1 5 12C5 10.9 4.1 10 3 10V7C3 5 4 4 6 4H18C20 4 21 5 21 7V10C19.9 10 19 10.9 19 12Z"
                      fill="#6F737C"
                    />
                    <path
                      d="M9.5 15.25C9.308 15.25 9.11597 15.177 8.96997 15.03C8.67697 14.737 8.67697 14.262 8.96997 13.969L13.97 8.96899C14.263 8.67599 14.738 8.67599 15.031 8.96899C15.324 9.26199 15.324 9.737 15.031 10.03L10.031 15.03C9.88401 15.177 9.692 15.25 9.5 15.25ZM10.52 9.5C10.52 8.948 10.073 8.5 9.52002 8.5H9.51001C8.95801 8.5 8.51501 8.948 8.51501 9.5C8.51501 10.052 8.96802 10.5 9.52002 10.5C10.072 10.5 10.52 10.052 10.52 9.5ZM15.52 14.5C15.52 13.948 15.073 13.5 14.52 13.5H14.51C13.958 13.5 13.515 13.948 13.515 14.5C13.515 15.052 13.968 15.5 14.52 15.5C15.072 15.5 15.52 15.052 15.52 14.5Z"
                      fill="#6F737C"
                    />
                  </svg>
                </span>
                <span class="label">Referral Withdrawal</span>
              </router-link>
            </a-menu-item>
          </a-sub-menu>
        </a-menu>
      </a-layout-sider>
      <a-layout-sider style="background: transparent">
        <a-menu mode="inline" :defaultOpenKeys="['Partner']">
          <a-sub-menu key="Partner" title="Partner" style="font-weight: bold">
            <a-menu-item v-if="adminRoles.includes(userRole)">
              <router-link to="/banners">
                <span class="icon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22.447 7.61017L21.2469 16.19C20.9869 17.99 19.9969 18.94 18.3669 18.94C18.2369 18.94 18.097 18.93 17.957 18.92C17.987 18.73 17.9969 18.5401 17.9969 18.3301V9.65997C17.9969 7.88997 17.117 7.00006 15.337 7.00006H5.36694C5.36694 6.98006 5.37695 6.94999 5.37695 6.92999L5.61694 5.25006C5.75694 4.23006 6.14695 3.48008 6.74695 3.02008C7.35695 2.57008 8.17702 2.41011 9.19702 2.55011L19.757 4.03009C20.767 4.18009 21.5171 4.55998 21.9771 5.16998C22.437 5.76998 22.597 6.60017 22.447 7.61017Z"
                      fill="#6F737C"
                    />
                    <path
                      opacity="0.4"
                      d="M15.335 6.99805H4.66699C2.88899 6.99805 2 7.88704 2 9.66504V18.333C2 20.111 2.88899 21 4.66699 21H15.335C17.113 21 18.002 20.111 18.002 18.333V9.66504C18.003 7.88604 17.114 6.99805 15.335 6.99805Z"
                      fill="#6F737C"
                    />
                    <path
                      d="M16.412 14.972L13.3 11.8502C12.95 11.5102 12.38 11.5102 12.04 11.8502L7.65002 16.2401C7.47702 16.4131 7.19302 16.4131 7.02002 16.2401L6.18994 15.41C5.83994 15.06 5.27005 15.06 4.93005 15.41L3.58801 16.7521C3.53201 16.8081 3.5 16.8852 3.5 16.9642V18.3302C3.5 19.2802 3.72004 19.5001 4.67004 19.5001H15.34C16.28 19.5001 16.5 19.2802 16.5 18.3302V15.1842C16.5 15.1042 16.469 15.028 16.412 14.972Z"
                      fill="#6F737C"
                    />
                    <path
                      d="M6.50195 12.5C5.95095 12.5 5.5 12.052 5.5 11.5C5.5 10.948 5.94302 10.5 6.49402 10.5H6.50195C7.05295 10.5 7.5 10.948 7.5 11.5C7.5 12.052 7.05295 12.5 6.50195 12.5Z"
                      fill="#6F737C"
                    />
                  </svg>
                </span>
                <span class="label">Banner</span>
              </router-link>
            </a-menu-item>

            <a-menu-item v-if="adminRoles.includes(userRole)">
              <router-link to="/categories">
                <span class="icon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14 5.5V18.5C14 19.5 14.5 20 15.5 20H18.5C19.5 20 20 19.5 20 18.5V5.5C20 4.5 19.5 4 18.5 4H15.5C14.5 4 14 4.5 14 5.5Z"
                      fill="#6F737C"
                    />
                    <path
                      opacity="0.4"
                      d="M4 15.5V18.5C4 19.5 4.5 20 5.5 20H8.5C9.5 20 10 19.5 10 18.5V15.5C10 14.5 9.5 14 8.5 14H5.5C4.5 14 4 14.5 4 15.5Z"
                      fill="#6F737C"
                    />
                    <path
                      opacity="0.4"
                      d="M4 5.5V8.5C4 9.5 4.5 10 5.5 10H8.5C9.5 10 10 9.5 10 8.5V5.5C10 4.5 9.5 4 8.5 4H5.5C4.5 4 4 4.5 4 5.5Z"
                      fill="#6F737C"
                    />
                  </svg>
                </span>
                <span class="label">Category</span>
              </router-link>
            </a-menu-item>
            <a-menu-item v-if="adminRoles.includes(userRole)">
              <router-link to="/partners">
                <span class="icon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.4"
                      d="M12.7481 8.24105L14.2581 11.288C14.3721 11.517 14.591 11.676 14.845 11.713L18.3311 12.217C18.9711 12.31 19.226 13.0941 18.762 13.5431L16.241 15.989C16.057 16.168 15.973 16.426 16.016 16.678L16.593 20.025C16.71 20.704 15.996 21.222 15.385 20.902L12.361 19.319C12.134 19.2 11.864 19.2 11.637 19.319L8.61502 20.9C8.00302 21.22 7.28806 20.702 7.40506 20.022L7.98098 16.677C8.02498 16.424 7.94001 16.167 7.75601 15.988L5.23501 13.542C4.77201 13.093 5.02704 12.308 5.66604 12.216L9.152 11.712C9.406 11.675 9.62504 11.516 9.73904 11.287L11.2491 8.24001C11.5581 7.62301 12.4421 7.62305 12.7481 8.24105Z"
                      fill="#6F737C"
                    />
                    <path
                      d="M6 9.75C5.586 9.75 5.25 9.414 5.25 9V3C5.25 2.586 5.586 2.25 6 2.25C6.414 2.25 6.75 2.586 6.75 3V9C6.75 9.414 6.414 9.75 6 9.75ZM12.75 5V3C12.75 2.586 12.414 2.25 12 2.25C11.586 2.25 11.25 2.586 11.25 3V5C11.25 5.414 11.586 5.75 12 5.75C12.414 5.75 12.75 5.414 12.75 5ZM18.75 9V3C18.75 2.586 18.414 2.25 18 2.25C17.586 2.25 17.25 2.586 17.25 3V9C17.25 9.414 17.586 9.75 18 9.75C18.414 9.75 18.75 9.414 18.75 9Z"
                      fill="#6F737C"
                    />
                  </svg>
                </span>
                <span class="label">Brand</span>
              </router-link>
            </a-menu-item>
          </a-sub-menu>
        </a-menu>
      </a-layout-sider>
      <a-layout-sider style="background: transparent">
        <a-menu mode="inline" :defaultOpenKeys="['Subscription Packages']">
          <a-sub-menu
            key="Subscription Packages"
            title="Subscription Packages"
            style="font-weight: bold"
          >
            <a-menu-item v-if="adminRoles.includes(userRole)">
              <router-link to="/packages">
                <span class="icon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.4"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M6.00191 21H17.999C19.999 21 21.1529 19.477 20.9849 17.715L20.318 10.716C20.171 9.176 19 8 17.332 8H6.67196C5.00396 8 3.832 9.176 3.686 10.716L3.019 17.715C2.848 19.477 4.00191 21 6.00191 21Z"
                      fill="#6F737C"
                    />
                    <path
                      d="M8.02002 13C7.46802 13 7.01501 12.552 7.01501 12C7.01501 11.448 7.45801 11 8.01001 11H8.02002C8.57302 11 9.02002 11.448 9.02002 12C9.02002 12.552 8.57202 13 8.02002 13Z"
                      fill="#6F737C"
                    />
                    <path
                      d="M16.02 13C15.468 13 15.015 12.552 15.015 12C15.015 11.448 15.458 11 16.01 11H16.02C16.573 11 17.02 11.448 17.02 12C17.02 12.552 16.572 13 16.02 13Z"
                      fill="#6F737C"
                    />
                    <path
                      d="M16.75 7V8H15.25V7C15.25 5.21 13.79 3.75 12 3.75C10.21 3.75 8.75 5.21 8.75 7V8H7.25V7C7.25 4.38 9.38 2.25 12 2.25C14.62 2.25 16.75 4.38 16.75 7Z"
                      fill="#6F737C"
                    />
                  </svg>
                </span>
                <span class="label">Package</span>
              </router-link>
            </a-menu-item>
          </a-sub-menu>
        </a-menu>
      </a-layout-sider>
      <a-layout-sider style="background: transparent">
        <a-menu mode="inline" :defaultOpenKeys="['Offers']">
          <a-sub-menu key="Offers" title="Offers" style="font-weight: bold">
            <a-menu-item v-if="adminRoles.includes(userRole)">
              <router-link to="/offers">
                <span class="icon"
                  ><svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.4"
                      d="M6.31498 17H17.685C18.369 17 18.966 16.538 19.137 15.876L20.956 8.84297C21.241 7.79597 20.075 6.956 19.173 7.558L16.857 9.10198C16.36 9.43298 15.684 9.24597 15.43 8.70497L13.06 3.674C12.637 2.776 11.359 2.77597 10.936 3.67497L8.572 8.704C8.318 9.245 7.64196 9.43298 7.14396 9.10198L4.82799 7.558C3.92499 6.956 2.75897 7.79697 3.04497 8.84297L4.864 15.876C5.034 16.538 5.63098 17 6.31498 17Z"
                      fill="#6F737C"
                    />
                    <path
                      d="M18.5 20.75H5.5C5.086 20.75 4.75 20.414 4.75 20C4.75 19.586 5.086 19.25 5.5 19.25H18.5C18.914 19.25 19.25 19.586 19.25 20C19.25 20.414 18.914 20.75 18.5 20.75Z"
                      fill="#6F737C"
                    />
                  </svg>
                </span>
                <span class="label">Premium Offer</span>
              </router-link>
            </a-menu-item>
            <a-menu-item v-if="adminRoles.includes(userRole)">
              <router-link to="/special-offers">
                <span class="icon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.4"
                      d="M15.08 18.541L13.061 20.56C12.475 21.146 11.5249 21.146 10.9399 20.56L8.92102 18.541C8.39302 18.013 7.55696 17.954 6.95996 18.402L5.60095 19.421C4.94195 19.915 4.00098 19.445 4.00098 18.621V6C4.00098 4 5.00098 3 7.00098 3H17.001C19.001 3 20.001 4 20.001 6V18.621C20.001 19.445 19.06 19.915 18.401 19.421L17.042 18.402C16.444 17.954 15.608 18.013 15.08 18.541Z"
                      fill="#6F737C"
                    />
                    <path
                      d="M12.454 7.27999L13.371 9.12297C13.44 9.26197 13.573 9.35799 13.727 9.37999L15.844 9.68499C16.232 9.74099 16.3869 10.215 16.1059 10.487L14.576 11.967C14.464 12.075 14.4129 12.231 14.4399 12.384L14.79 14.409C14.861 14.82 14.428 15.133 14.056 14.94L12.22 13.982C12.082 13.91 11.918 13.91 11.78 13.982L9.94493 14.939C9.57293 15.133 9.13894 14.819 9.20994 14.407L9.56004 12.383C9.58604 12.23 9.53493 12.074 9.42393 11.966L7.89402 10.486C7.61302 10.214 7.76799 9.73998 8.15599 9.68398L10.2729 9.37899C10.4269 9.35699 10.56 9.261 10.629 9.122L11.546 7.27898C11.732 6.90598 12.268 6.90599 12.454 7.27999Z"
                      fill="#6F737C"
                    />
                  </svg>
                </span>
                <span class="label">Special Offer</span>
              </router-link>
            </a-menu-item>
            <a-menu-item v-if="adminRoles.includes(userRole)">
              <router-link to="/today-offers">
                <span class="icon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.4"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M20.5589 13.064L19.243 14.38C18.962 14.661 18.8039 15.043 18.8039 15.441V17.299C18.8039 18.13 18.1299 18.804 17.2989 18.804H15.441C15.043 18.804 14.662 18.962 14.38 19.243L13.0639 20.559C12.4759 21.147 11.524 21.147 10.936 20.559L9.61996 19.243C9.33895 18.962 8.95692 18.804 8.55892 18.804H6.70101C5.87001 18.804 5.196 18.13 5.196 17.299V15.441C5.196 15.043 5.03792 14.662 4.75692 14.38L3.441 13.064C2.853 12.476 2.853 11.524 3.441 10.936L4.75692 9.61999C5.03792 9.33899 5.196 8.95698 5.196 8.55898V6.70098C5.196 5.86998 5.87001 5.196 6.70101 5.196H8.55892C8.95692 5.196 9.33795 5.03798 9.61996 4.75698L10.936 3.441C11.524 2.853 12.4759 2.853 13.0639 3.441L14.38 4.75698C14.661 5.03798 15.043 5.196 15.441 5.196H17.2989C18.1299 5.196 18.8039 5.86998 18.8039 6.70098V8.55898C18.8039 8.95698 18.962 9.33799 19.243 9.61999L20.5589 10.936C21.1469 11.524 21.1469 12.476 20.5589 13.064Z"
                      fill="#6F737C"
                    />
                    <path
                      d="M9.5 15.25C9.308 15.25 9.11597 15.177 8.96997 15.03C8.67697 14.737 8.67697 14.262 8.96997 13.969L13.97 8.96899C14.263 8.67599 14.738 8.67599 15.031 8.96899C15.324 9.26199 15.324 9.737 15.031 10.03L10.031 15.03C9.88401 15.177 9.692 15.25 9.5 15.25ZM10.52 9.5C10.52 8.948 10.073 8.5 9.52002 8.5H9.51001C8.95801 8.5 8.51501 8.948 8.51501 9.5C8.51501 10.052 8.96802 10.5 9.52002 10.5C10.072 10.5 10.52 10.052 10.52 9.5ZM15.52 14.5C15.52 13.948 15.073 13.5 14.52 13.5H14.51C13.958 13.5 13.515 13.948 13.515 14.5C13.515 15.052 13.968 15.5 14.52 15.5C15.072 15.5 15.52 15.052 15.52 14.5Z"
                      fill="#6F737C"
                    />
                  </svg>
                </span>
                <span class="label">Today Offer</span>
              </router-link>
            </a-menu-item>
          </a-sub-menu>
        </a-menu>
      </a-layout-sider>
      <a-layout-sider style="background: transparent">
        <a-menu mode="inline" :defaultOpenKeys="['Reports']">
          <a-sub-menu key="Reports" title="Reports" style="font-weight: bold">
            <a-menu-item v-if="adminRoles.includes(userRole)">
              <router-link to="/subscripiton-report">
                <span class="icon">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 2C8.44772 2 8 2.44772 8 3C8 3.55228 8.44772 4 9 4H11C11.5523 4 12 3.55228 12 3C12 2.44772 11.5523 2 11 2H9Z"
                      fill="#111827"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M4 5C4 3.89543 4.89543 3 6 3C6 4.65685 7.34315 6 9 6H11C12.6569 6 14 4.65685 14 3C15.1046 3 16 3.89543 16 5V16C16 17.1046 15.1046 18 14 18H6C4.89543 18 4 17.1046 4 16V5ZM7 9C6.44772 9 6 9.44772 6 10C6 10.5523 6.44772 11 7 11H7.01C7.56228 11 8.01 10.5523 8.01 10C8.01 9.44772 7.56228 9 7.01 9H7ZM10 9C9.44772 9 9 9.44772 9 10C9 10.5523 9.44772 11 10 11H13C13.5523 11 14 10.5523 14 10C14 9.44772 13.5523 9 13 9H10ZM7 13C6.44772 13 6 13.4477 6 14C6 14.5523 6.44772 15 7 15H7.01C7.56228 15 8.01 14.5523 8.01 14C8.01 13.4477 7.56228 13 7.01 13H7ZM10 13C9.44772 13 9 13.4477 9 14C9 14.5523 9.44772 15 10 15H13C13.5523 15 14 14.5523 14 14C14 13.4477 13.5523 13 13 13H10Z"
                      fill="#111827"
                    />
                  </svg>
                </span>
                <span class="label">Subscription Report</span>
              </router-link>
            </a-menu-item>

            <a-menu-item v-if="adminRoles.includes(userRole)">
              <router-link to="/revenue-reports">
                <span class="icon">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 2C8.44772 2 8 2.44772 8 3C8 3.55228 8.44772 4 9 4H11C11.5523 4 12 3.55228 12 3C12 2.44772 11.5523 2 11 2H9Z"
                      fill="#111827"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M4 5C4 3.89543 4.89543 3 6 3C6 4.65685 7.34315 6 9 6H11C12.6569 6 14 4.65685 14 3C15.1046 3 16 3.89543 16 5V16C16 17.1046 15.1046 18 14 18H6C4.89543 18 4 17.1046 4 16V5ZM7 9C6.44772 9 6 9.44772 6 10C6 10.5523 6.44772 11 7 11H7.01C7.56228 11 8.01 10.5523 8.01 10C8.01 9.44772 7.56228 9 7.01 9H7ZM10 9C9.44772 9 9 9.44772 9 10C9 10.5523 9.44772 11 10 11H13C13.5523 11 14 10.5523 14 10C14 9.44772 13.5523 9 13 9H10ZM7 13C6.44772 13 6 13.4477 6 14C6 14.5523 6.44772 15 7 15H7.01C7.56228 15 8.01 14.5523 8.01 14C8.01 13.4477 7.56228 13 7.01 13H7ZM10 13C9.44772 13 9 13.4477 9 14C9 14.5523 9.44772 15 10 15H13C13.5523 15 14 14.5523 14 14C14 13.4477 13.5523 13 13 13H10Z"
                      fill="#111827"
                    />
                  </svg>
                </span>
                <span class="label">Revenue Report</span>
              </router-link>
            </a-menu-item>

            <a-menu-item v-if="adminRoles.includes(userRole)">
              <router-link to="/scan-histories">
                <span class="icon">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 2C8.44772 2 8 2.44772 8 3C8 3.55228 8.44772 4 9 4H11C11.5523 4 12 3.55228 12 3C12 2.44772 11.5523 2 11 2H9Z"
                      fill="#111827"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M4 5C4 3.89543 4.89543 3 6 3C6 4.65685 7.34315 6 9 6H11C12.6569 6 14 4.65685 14 3C15.1046 3 16 3.89543 16 5V16C16 17.1046 15.1046 18 14 18H6C4.89543 18 4 17.1046 4 16V5ZM7 9C6.44772 9 6 9.44772 6 10C6 10.5523 6.44772 11 7 11H7.01C7.56228 11 8.01 10.5523 8.01 10C8.01 9.44772 7.56228 9 7.01 9H7ZM10 9C9.44772 9 9 9.44772 9 10C9 10.5523 9.44772 11 10 11H13C13.5523 11 14 10.5523 14 10C14 9.44772 13.5523 9 13 9H10ZM7 13C6.44772 13 6 13.4477 6 14C6 14.5523 6.44772 15 7 15H7.01C7.56228 15 8.01 14.5523 8.01 14C8.01 13.4477 7.56228 13 7.01 13H7ZM10 13C9.44772 13 9 13.4477 9 14C9 14.5523 9.44772 15 10 15H13C13.5523 15 14 14.5523 14 14C14 13.4477 13.5523 13 13 13H10Z"
                      fill="#111827"
                    />
                  </svg>
                </span>
                <span class="label">Scan Histories</span>
              </router-link>
            </a-menu-item>
            <a-menu-item v-if="adminRoles.includes(userRole)">
              <router-link to="/brand-visit-histories">
                <span class="icon">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 2C8.44772 2 8 2.44772 8 3C8 3.55228 8.44772 4 9 4H11C11.5523 4 12 3.55228 12 3C12 2.44772 11.5523 2 11 2H9Z"
                      fill="#111827"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M4 5C4 3.89543 4.89543 3 6 3C6 4.65685 7.34315 6 9 6H11C12.6569 6 14 4.65685 14 3C15.1046 3 16 3.89543 16 5V16C16 17.1046 15.1046 18 14 18H6C4.89543 18 4 17.1046 4 16V5ZM7 9C6.44772 9 6 9.44772 6 10C6 10.5523 6.44772 11 7 11H7.01C7.56228 11 8.01 10.5523 8.01 10C8.01 9.44772 7.56228 9 7.01 9H7ZM10 9C9.44772 9 9 9.44772 9 10C9 10.5523 9.44772 11 10 11H13C13.5523 11 14 10.5523 14 10C14 9.44772 13.5523 9 13 9H10ZM7 13C6.44772 13 6 13.4477 6 14C6 14.5523 6.44772 15 7 15H7.01C7.56228 15 8.01 14.5523 8.01 14C8.01 13.4477 7.56228 13 7.01 13H7ZM10 13C9.44772 13 9 13.4477 9 14C9 14.5523 9.44772 15 10 15H13C13.5523 15 14 14.5523 14 14C14 13.4477 13.5523 13 13 13H10Z"
                      fill="#111827"
                    />
                  </svg>
                </span>
                <span class="label">Brand Visit Histories</span>
              </router-link>
            </a-menu-item>
            <a-menu-item v-if="adminRoles.includes(userRole)">
              <router-link to="/partner-offer-avail-histories">
                <span class="icon">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 2C8.44772 2 8 2.44772 8 3C8 3.55228 8.44772 4 9 4H11C11.5523 4 12 3.55228 12 3C12 2.44772 11.5523 2 11 2H9Z"
                      fill="#111827"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M4 5C4 3.89543 4.89543 3 6 3C6 4.65685 7.34315 6 9 6H11C12.6569 6 14 4.65685 14 3C15.1046 3 16 3.89543 16 5V16C16 17.1046 15.1046 18 14 18H6C4.89543 18 4 17.1046 4 16V5ZM7 9C6.44772 9 6 9.44772 6 10C6 10.5523 6.44772 11 7 11H7.01C7.56228 11 8.01 10.5523 8.01 10C8.01 9.44772 7.56228 9 7.01 9H7ZM10 9C9.44772 9 9 9.44772 9 10C9 10.5523 9.44772 11 10 11H13C13.5523 11 14 10.5523 14 10C14 9.44772 13.5523 9 13 9H10ZM7 13C6.44772 13 6 13.4477 6 14C6 14.5523 6.44772 15 7 15H7.01C7.56228 15 8.01 14.5523 8.01 14C8.01 13.4477 7.56228 13 7.01 13H7ZM10 13C9.44772 13 9 13.4477 9 14C9 14.5523 9.44772 15 10 15H13C13.5523 15 14 14.5523 14 14C14 13.4477 13.5523 13 13 13H10Z"
                      fill="#111827"
                    />
                  </svg>
                </span>
                <span class="label">Avail Offer Histories</span>
              </router-link>
            </a-menu-item>
            <a-menu-item v-if="adminRoles.includes(userRole)">
              <router-link to="/partner-offer-view-histories">
                <span class="icon">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 2C8.44772 2 8 2.44772 8 3C8 3.55228 8.44772 4 9 4H11C11.5523 4 12 3.55228 12 3C12 2.44772 11.5523 2 11 2H9Z"
                      fill="#111827"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M4 5C4 3.89543 4.89543 3 6 3C6 4.65685 7.34315 6 9 6H11C12.6569 6 14 4.65685 14 3C15.1046 3 16 3.89543 16 5V16C16 17.1046 15.1046 18 14 18H6C4.89543 18 4 17.1046 4 16V5ZM7 9C6.44772 9 6 9.44772 6 10C6 10.5523 6.44772 11 7 11H7.01C7.56228 11 8.01 10.5523 8.01 10C8.01 9.44772 7.56228 9 7.01 9H7ZM10 9C9.44772 9 9 9.44772 9 10C9 10.5523 9.44772 11 10 11H13C13.5523 11 14 10.5523 14 10C14 9.44772 13.5523 9 13 9H10ZM7 13C6.44772 13 6 13.4477 6 14C6 14.5523 6.44772 15 7 15H7.01C7.56228 15 8.01 14.5523 8.01 14C8.01 13.4477 7.56228 13 7.01 13H7ZM10 13C9.44772 13 9 13.4477 9 14C9 14.5523 9.44772 15 10 15H13C13.5523 15 14 14.5523 14 14C14 13.4477 13.5523 13 13 13H10Z"
                      fill="#111827"
                    />
                  </svg>
                </span>
                <span class="label">Offer View Histories</span>
              </router-link>
            </a-menu-item>
            <a-menu-item v-if="partnerRoles.includes(userRole)">
              <router-link to="/partner-report">
                <span class="icon">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 2C8.44772 2 8 2.44772 8 3C8 3.55228 8.44772 4 9 4H11C11.5523 4 12 3.55228 12 3C12 2.44772 11.5523 2 11 2H9Z"
                      fill="#111827"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M4 5C4 3.89543 4.89543 3 6 3C6 4.65685 7.34315 6 9 6H11C12.6569 6 14 4.65685 14 3C15.1046 3 16 3.89543 16 5V16C16 17.1046 15.1046 18 14 18H6C4.89543 18 4 17.1046 4 16V5ZM7 9C6.44772 9 6 9.44772 6 10C6 10.5523 6.44772 11 7 11H7.01C7.56228 11 8.01 10.5523 8.01 10C8.01 9.44772 7.56228 9 7.01 9H7ZM10 9C9.44772 9 9 9.44772 9 10C9 10.5523 9.44772 11 10 11H13C13.5523 11 14 10.5523 14 10C14 9.44772 13.5523 9 13 9H10ZM7 13C6.44772 13 6 13.4477 6 14C6 14.5523 6.44772 15 7 15H7.01C7.56228 15 8.01 14.5523 8.01 14C8.01 13.4477 7.56228 13 7.01 13H7ZM10 13C9.44772 13 9 13.4477 9 14C9 14.5523 9.44772 15 10 15H13C13.5523 15 14 14.5523 14 14C14 13.4477 13.5523 13 13 13H10Z"
                      fill="#111827"
                    />
                  </svg>
                </span>
                <span class="label">Report</span>
              </router-link>
            </a-menu-item>
          </a-sub-menu>
        </a-menu>
      </a-layout-sider>
      <a-layout-sider style="background: transparent">
        <a-menu mode="inline" :defaultOpenKeys="['Settings']">
          <a-sub-menu key="Settings" title="Settings" style="font-weight: bold">
            <a-menu-item v-if="adminRoles.includes(userRole)">
              <router-link to="/icons">
                <span class="icon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.09497 12.573C5.62497 11.624 6.62899 11.035 7.71899 11.036L18.9861 11.042L19 11.036V10C19 8 18 7 16 7H10L8 5H5C3 5 2 6 2 8V18.496L2.0061 18.493C2.0081 18.255 2.06092 18.011 2.19092 17.78L5.09497 12.573Z"
                      fill="#6F737C"
                    />
                    <path
                      opacity="0.4"
                      d="M20.4991 11.043L18.9871 11.042L7.72001 11.036C6.63001 11.035 5.62499 11.624 5.09599 12.573L2.19194 17.78C2.06294 18.012 2.00912 18.255 2.00712 18.493C2.00112 19.279 2.62497 20 3.50297 20H16.284C17.373 20 18.377 19.412 18.906 18.464L21.8101 13.263C22.3651 12.268 21.6421 11.043 20.4991 11.043Z"
                      fill="#6F737C"
                    />
                  </svg>
                </span>
                <span class="label">Icon Library</span>
              </router-link>
            </a-menu-item>
            <a-menu-item>
              <router-link to="/change-password">
                <span class="icon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.009 10.5C12.2182 10.5 14.009 8.70914 14.009 6.5C14.009 4.29086 12.2182 2.5 10.009 2.5C7.79989 2.5 6.00903 4.29086 6.00903 6.5C6.00903 8.70914 7.79989 10.5 10.009 10.5Z"
                      fill="#6F737C"
                    />
                    <path
                      opacity="0.4"
                      d="M15.505 13.666C15.51 13.714 15.513 13.784 15.473 13.859C15.171 14.42 15 15.062 15 15.75V16.1899C14.36 16.7199 14 17.5499 14 18.5699V20.9301C14 21.0201 14 21.1099 14.01 21.1899V21.2C14.01 21.21 14.01 21.21 14.01 21.22C14 21.38 13.86 21.5 13.71 21.5H6C3.71 21.5 2.5 20.3 2.5 18.02C2.5 15.47 3.94 12.5 8 12.5H12C13.444 12.5 14.558 12.8721 15.389 13.4771C15.462 13.5301 15.5 13.618 15.505 13.666Z"
                      fill="#6F737C"
                    />
                    <path
                      d="M21.5 17.075V15.75C21.5 14.372 20.379 13.25 19 13.25C17.621 13.25 16.5 14.372 16.5 15.75V17.075C15.845 17.237 15.5 17.724 15.5 18.572V20.929C15.5 21.976 16 22.5 17 22.5H21C22 22.5 22.5 21.976 22.5 20.929V18.572C22.5 17.723 22.155 17.236 21.5 17.075ZM19 14.75C19.552 14.75 20 15.199 20 15.75V17H18V15.75C18 15.199 18.448 14.75 19 14.75Z"
                      fill="#6F737C"
                    />
                  </svg>
                </span>
                <span class="label">Password Change</span>
              </router-link>
            </a-menu-item>
          </a-sub-menu>
        </a-menu>
      </a-layout-sider>
      <a-layout-sider style="background: transparent">
        <a-menu mode="inline" :defaultOpenKeys="['Notification']">
          <a-sub-menu
            key="Notification"
            title="Notification"
            style="font-weight: bold"
          >
            <a-menu-item v-if="adminRoles.includes(userRole)">
              <router-link to="/promotion">
                <span class="icon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21 3.93V17.07C21 17.85 20.1 18.28 19.5 17.8L18.47 16.97C17.94 16.54 17.36 16.18 16.75 15.89C16.5 15.77 16.25 15.66 16 15.57V5.43C16.25 5.34 16.5 5.22999 16.75 5.10999C17.36 4.81999 17.94 4.46004 18.47 4.03004L19.5 3.20002C20.1 2.72002 21 3.15 21 3.93Z"
                      fill="#6F737C"
                    />
                    <path
                      opacity="0.4"
                      d="M15.25 5.66998C14.47 5.88998 13.66 6 12.84 6H7.5C5.01 6 3 8.02 3 10.5C3 12.98 5.01 15 7.5 15L9.94702 19.894C10.286 20.572 10.978 21 11.736 21H13L12 15H12.84C13.66 15 14.47 15.11 15.25 15.33C15.5 15.4 15.75 15.48 16 15.57V5.42999C15.75 5.51999 15.5 5.59998 15.25 5.66998Z"
                      fill="#6F737C"
                    />
                  </svg>
                </span>
                <span class="label">Promotion</span>
              </router-link>
            </a-menu-item>
          </a-sub-menu>
        </a-menu>
      </a-layout-sider>

      <a-menu-item>
        <div @click="logout" style="margin-left: 16px; margin-top: 5px">
          <span class="icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-log-out"
            >
              <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
              <polyline points="16 17 21 12 16 7"></polyline>
              <line x1="21" y1="12" x2="9" y2="12"></line>
            </svg>
          </span>
          <span class="label">Logout</span>
        </div>
      </a-menu-item>
    </a-menu>
    <!-- / Sidebar Navigation Menu -->
  </a-layout-sider>
  <!-- / Main Sidebar -->
</template>

<script>
import { sendRequest } from "@/http/axios.method";

export default {
  props: {
    // Sidebar collapsed status.
    sidebarCollapsed: {
      type: Boolean,
      default: false,
    },

    // Main sidebar color.
    sidebarColor: {
      type: String,
      default: "primary",
    },

    // Main sidebar theme : light, white, dark.
    sidebarTheme: {
      type: String,
      default: "light",
    },
  },
  data() {
    return {
      // sidebarCollapsedModel: this.sidebarCollapsed,
      config: {
        Authorization: "Bearer " + this.$store.state.authUser.token,
      },
      superAdminRoles: ["super-admin", "developer"],
      adminRoles: ["super-admin", "developer", "admin"],
      partnerRoles: ["partner"],
      developerRoles: ["developer"],
      userRole: this.$store.state.authUser.role,
    };
  },
  methods: {
    logout() {
      sendRequest("post", "logout", {}, this.config)
        .then((response) => {
          if (response.success) {
            this.$store.dispatch("logout");
            this.$router.push("/login");
          } else {
            if (response.status_code === 401) {
              this.$store.dispatch("logout");
              this.$router.push("/login");
            }
          }
        })
        .catch(() => {})
        .finally(() => {});
    },
  },
};
</script>
