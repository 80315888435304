<template>
  <!-- Layout Header ( Navbar ) -->
  <a-layout-header v-if="currentRouteName !== 'PartnerLogin'">
    <div class="header-col header-brand">
      <h6>
        <img
          src="/images/affinity-logo.png"
          height="30"
          width="30"
          :alt="title"
        />
        {{ title }}
      </h6>

      <!-- Trigger Button For Navigation Menu For Small Screens -->
      <!--			<a-button type="link" @click="collapseNav = collapseNav ? 0 : 1 " class="btn-menu-trigger">-->
      <!--				<svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"/></svg>-->
      <!--			</a-button>-->
      <!-- Trigger Button For Navigation Menu For Small Screens -->
    </div>
    <div class="header-col header-nav">
      <!-- Navigation Menu For Large Screens -->
      <a-menu mode="horizontal" class="menu-large">
        <!--				<a-menu-item>-->
        <!--					<router-link to="/dashboard" class="nav-link" @click="e => e.preventDefault()">-->
        <!--						<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">-->
        <!--							<path class="fill-muted" d="M3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V6C17 6.55228 16.5523 7 16 7H4C3.44772 7 3 6.55228 3 6V4Z" fill="#111827"/>-->
        <!--							<path class="fill-muted" d="M3 10C3 9.44771 3.44772 9 4 9H10C10.5523 9 11 9.44771 11 10V16C11 16.5523 10.5523 17 10 17H4C3.44772 17 3 16.5523 3 16V10Z" fill="#111827"/>-->
        <!--							<path class="fill-muted" d="M14 9C13.4477 9 13 9.44771 13 10V16C13 16.5523 13.4477 17 14 17H16C16.5523 17 17 16.5523 17 16V10C17 9.44771 16.5523 9 16 9H14Z" fill="#111827"/>-->
        <!--						</svg>-->
        <!--						<span>Dashboard</span>-->
        <!--					</router-link>-->
        <!--				</a-menu-item>-->
        <!--				<a-menu-item>-->
        <!--					<router-link to="/profile" class="nav-link" @click="e => e.preventDefault()">-->
        <!--						<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">-->
        <!--							<path class="fill-muted" fill-rule="evenodd" clip-rule="evenodd" d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM12 7C12 8.10457 11.1046 9 10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7ZM9.99993 11C7.98239 11 6.24394 12.195 5.45374 13.9157C6.55403 15.192 8.18265 16 9.99998 16C11.8173 16 13.4459 15.1921 14.5462 13.9158C13.756 12.195 12.0175 11 9.99993 11Z" fill="#111827"/>-->
        <!--						</svg>-->
        <!--						<span>Profile</span>-->
        <!--					</router-link>-->
        <!--				</a-menu-item>-->
        <!--				<a-menu-item>-->
        <!--					<router-link to="/sign-in" class="nav-link" @click="e => e.preventDefault()">-->
        <!--						<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">-->
        <!--							<path class="fill-muted" fill-rule="evenodd" clip-rule="evenodd" d="M6 2C5.44772 2 5 2.44772 5 3V4H4C2.89543 4 2 4.89543 2 6V16C2 17.1046 2.89543 18 4 18H16C17.1046 18 18 17.1046 18 16V6C18 4.89543 17.1046 4 16 4H15V3C15 2.44772 14.5523 2 14 2C13.4477 2 13 2.44772 13 3V4H7V3C7 2.44772 6.55228 2 6 2ZM6 7C5.44772 7 5 7.44772 5 8C5 8.55228 5.44772 9 6 9H14C14.5523 9 15 8.55228 15 8C15 7.44772 14.5523 7 14 7H6Z" fill="#111827"/>-->
        <!--						</svg>-->
        <!--						<span>Sign In</span>-->
        <!--					</router-link>-->
        <!--				</a-menu-item>-->
        <!--				<a-menu-item>-->
        <!--					<router-link to="/sign-up" class="nav-link" @click="e => e.preventDefault()">-->
        <!--						<svg width="14px" height="14px" class="fill-muted" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">-->
        <!--							<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">-->
        <!--								<g class="fill-muted" id="Tables" transform="translate(-58.000000, -507.000000)" fill="#BFBFBF" fill-rule="nonzero">-->
        <!--									<g id="sidebar" transform="translate(33.000000, 43.000000)">-->
        <!--										<g id="sign-up" transform="translate(16.000000, 455.000000)">-->
        <!--											<g id="duplicate" transform="translate(9.000000, 9.000000)">-->
        <!--												<path d="M4,6 C4,4.89543 4.89543,4 6,4 L12,4 C13.1046,4 14,4.89543 14,6 L14,12 C14,13.1046 13.1046,14 12,14 L6,14 C4.89543,14 4,13.1046 4,12 L4,6 Z" id="Path"></path>-->
        <!--												<path d="M2,0 C0.89543,0 0,0.89543 0,2 L0,8 C0,9.1046 0.89543,10 2,10 L2,4 C2,2.8954305 2.8954305,2 4,2 L10,2 L10,2 C10,0.89543 9.1046,0 8,0 L2,0 Z" id="Path"></path>-->
        <!--											</g>-->
        <!--										</g>-->
        <!--									</g>-->
        <!--								</g>-->
        <!--							</g>-->
        <!--						</svg>-->
        <!--						<span>Sign Up</span>-->
        <!--					</router-link>-->
        <!--				</a-menu-item>-->
      </a-menu>
      <!-- / Navigation Menu For Large Screens -->

      <!-- Collapsible Navigation Menu For Small Screens -->
      <!--			<div class="menu-small">-->
      <!--				-->
      <!--				&lt;!&ndash; Collapsible Component For Navigation Menu For Small Screens &ndash;&gt;-->
      <!--				<a-collapse v-model="collapseNav" accordion>-->
      <!--					<a-collapse-panel key="1">-->

      <!--						&lt;!&ndash; Navigation Menu For Small Screens &ndash;&gt;-->
      <!--						<a-menu mode="vertical">-->
      <!--							<a-menu-item>-->
      <!--								<router-link to="/dashboard" class="nav-link" @click="e => e.preventDefault()">-->
      <!--									<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">-->
      <!--										<path class="fill-muted" d="M3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V6C17 6.55228 16.5523 7 16 7H4C3.44772 7 3 6.55228 3 6V4Z" fill="#111827"/>-->
      <!--										<path class="fill-muted" d="M3 10C3 9.44771 3.44772 9 4 9H10C10.5523 9 11 9.44771 11 10V16C11 16.5523 10.5523 17 10 17H4C3.44772 17 3 16.5523 3 16V10Z" fill="#111827"/>-->
      <!--										<path class="fill-muted" d="M14 9C13.4477 9 13 9.44771 13 10V16C13 16.5523 13.4477 17 14 17H16C16.5523 17 17 16.5523 17 16V10C17 9.44771 16.5523 9 16 9H14Z" fill="#111827"/>-->
      <!--									</svg>-->
      <!--									<span>Dashboard</span>-->
      <!--								</router-link>-->
      <!--							</a-menu-item>-->
      <!--							<a-menu-item>-->
      <!--								<router-link to="/profile" class="nav-link" @click="e => e.preventDefault()">-->
      <!--									<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">-->
      <!--										<path class="fill-muted" fill-rule="evenodd" clip-rule="evenodd" d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM12 7C12 8.10457 11.1046 9 10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7ZM9.99993 11C7.98239 11 6.24394 12.195 5.45374 13.9157C6.55403 15.192 8.18265 16 9.99998 16C11.8173 16 13.4459 15.1921 14.5462 13.9158C13.756 12.195 12.0175 11 9.99993 11Z" fill="#111827"/>-->
      <!--									</svg>-->
      <!--									<span>Profile</span>-->
      <!--								</router-link>-->
      <!--							</a-menu-item>-->
      <!--							<a-menu-item>-->
      <!--								<router-link to="/sign-in" class="nav-link" @click="e => e.preventDefault()">-->
      <!--									<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">-->
      <!--										<path class="fill-muted" fill-rule="evenodd" clip-rule="evenodd" d="M6 2C5.44772 2 5 2.44772 5 3V4H4C2.89543 4 2 4.89543 2 6V16C2 17.1046 2.89543 18 4 18H16C17.1046 18 18 17.1046 18 16V6C18 4.89543 17.1046 4 16 4H15V3C15 2.44772 14.5523 2 14 2C13.4477 2 13 2.44772 13 3V4H7V3C7 2.44772 6.55228 2 6 2ZM6 7C5.44772 7 5 7.44772 5 8C5 8.55228 5.44772 9 6 9H14C14.5523 9 15 8.55228 15 8C15 7.44772 14.5523 7 14 7H6Z" fill="#111827"/>-->
      <!--									</svg>-->
      <!--									<span>Sign In</span>-->
      <!--								</router-link>-->
      <!--							</a-menu-item>-->
      <!--							<a-menu-item>-->
      <!--								<router-link to="/sign-up" class="nav-link" @click="e => e.preventDefault()">-->
      <!--									<svg width="20" height="20" class="fill-muted" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">-->
      <!--										<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">-->
      <!--											<g class="fill-muted" id="Tables" transform="translate(-58.000000, -507.000000)" fill="#BFBFBF" fill-rule="nonzero">-->
      <!--												<g id="sidebar" transform="translate(33.000000, 43.000000)">-->
      <!--													<g id="sign-up" transform="translate(16.000000, 455.000000)">-->
      <!--														<g id="duplicate" transform="translate(9.000000, 9.000000)">-->
      <!--															<path d="M4,6 C4,4.89543 4.89543,4 6,4 L12,4 C13.1046,4 14,4.89543 14,6 L14,12 C14,13.1046 13.1046,14 12,14 L6,14 C4.89543,14 4,13.1046 4,12 L4,6 Z" id="Path"></path>-->
      <!--															<path d="M2,0 C0.89543,0 0,0.89543 0,2 L0,8 C0,9.1046 0.89543,10 2,10 L2,4 C2,2.8954305 2.8954305,2 4,2 L10,2 L10,2 C10,0.89543 9.1046,0 8,0 L2,0 Z" id="Path"></path>-->
      <!--														</g>-->
      <!--													</g>-->
      <!--												</g>-->
      <!--											</g>-->
      <!--										</g>-->
      <!--									</svg>-->
      <!--									<span>Sign Up</span>-->
      <!--								</router-link>-->
      <!--							</a-menu-item>-->
      <!--						</a-menu>-->
      <!--						&lt;!&ndash; / Navigation Menu For Small Screens &ndash;&gt;-->

      <!--					</a-collapse-panel>-->
      <!--				</a-collapse>-->
      <!--				&lt;!&ndash; / Collapsible Component For Navigation Menu For Small Screens &ndash;&gt;-->

      <!--			</div>-->
      <!-- / Collapsible Navigation Menu For Small Screens -->
    </div>
  </a-layout-header>
  <!-- / Layout Header ( Navbar ) -->
</template>

<script>
export default {
  data() {
    return {
      // Collapse navigation value.
      // Binded model property for "Collapsible Navigation Menu" collapsed status .
      collapseNav: 0,
      title: process.env.VUE_APP_NAME,
      routeName: this.$route.name,
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-link svg {
  margin-right: 5px;
  vertical-align: middle;
}
.nav-link span {
  vertical-align: middle;
}
.ant-menu-submenu-popup {
  width: 100%;
}
</style>
